.ongoing-content-wrapper {
  ol.lst-kix_b6cuw8nv330h-3.start {
    counter-reset: lst-ctn-kix_b6cuw8nv330h-3 0;
  }

  ul.lst-kix_orrx7eq3mcel-8 {
    list-style-type: none;
  }

  ul.lst-kix_orrx7eq3mcel-7 {
    list-style-type: none;
  }

  ul.lst-kix_orrx7eq3mcel-6 {
    list-style-type: none;
  }

  ul.lst-kix_orrx7eq3mcel-5 {
    list-style-type: none;
  }

  ul.lst-kix_orrx7eq3mcel-4 {
    list-style-type: none;
  }

  .lst-kix_b6cuw8nv330h-1 > li:before {
    content: '' counter(lst-ctn-kix_b6cuw8nv330h-1, lower-latin) '. ';
  }

  ul.lst-kix_orrx7eq3mcel-3 {
    list-style-type: none;
  }

  ul.lst-kix_orrx7eq3mcel-2 {
    list-style-type: none;
  }

  ul.lst-kix_orrx7eq3mcel-1 {
    list-style-type: none;
  }

  .lst-kix_3myohsi2w1ck-2 > li {
    counter-increment: lst-ctn-kix_3myohsi2w1ck-2;
  }

  .lst-kix_b6cuw8nv330h-0 > li:before {
    content: '' counter(lst-ctn-kix_b6cuw8nv330h-0, decimal) '. ';
  }

  ol.lst-kix_3myohsi2w1ck-6.start {
    counter-reset: lst-ctn-kix_3myohsi2w1ck-6 0;
  }

  .lst-kix_3myohsi2w1ck-7 > li {
    counter-increment: lst-ctn-kix_3myohsi2w1ck-7;
  }

  ol.lst-kix_3myohsi2w1ck-1 {
    list-style-type: none;
  }

  ol.lst-kix_3myohsi2w1ck-2 {
    list-style-type: none;
  }

  .lst-kix_b6cuw8nv330h-3 > li {
    counter-increment: lst-ctn-kix_b6cuw8nv330h-3;
  }

  ol.lst-kix_3myohsi2w1ck-3 {
    list-style-type: none;
  }

  ol.lst-kix_b6cuw8nv330h-6.start {
    counter-reset: lst-ctn-kix_b6cuw8nv330h-6 0;
  }

  ol.lst-kix_3myohsi2w1ck-4 {
    list-style-type: none;
  }

  ol.lst-kix_3myohsi2w1ck-5 {
    list-style-type: none;
  }

  ol.lst-kix_3myohsi2w1ck-6 {
    list-style-type: none;
  }

  ol.lst-kix_3myohsi2w1ck-7 {
    list-style-type: none;
  }

  ol.lst-kix_3myohsi2w1ck-8 {
    list-style-type: none;
  }

  .lst-kix_3myohsi2w1ck-6 > li {
    counter-increment: lst-ctn-kix_3myohsi2w1ck-6;
  }

  ol.lst-kix_b6cuw8nv330h-4.start {
    counter-reset: lst-ctn-kix_b6cuw8nv330h-4 0;
  }

  .lst-kix_3myohsi2w1ck-1 > li:before {
    content: '' counter(lst-ctn-kix_3myohsi2w1ck-0, decimal) '.'
      counter(lst-ctn-kix_3myohsi2w1ck-1, decimal) ' ';
  }

  .lst-kix_b6cuw8nv330h-5 > li {
    counter-increment: lst-ctn-kix_b6cuw8nv330h-5;
  }

  ol.lst-kix_b6cuw8nv330h-7.start {
    counter-reset: lst-ctn-kix_b6cuw8nv330h-7 0;
  }

  .lst-kix_b6cuw8nv330h-0 > li {
    counter-increment: lst-ctn-kix_b6cuw8nv330h-0;
  }

  .lst-kix_3myohsi2w1ck-0 > li:before {
    content: 'Artikel ' counter(lst-ctn-kix_3myohsi2w1ck-0, decimal) '. ';
  }

  ol.lst-kix_3myohsi2w1ck-2.start {
    counter-reset: lst-ctn-kix_3myohsi2w1ck-2 0;
  }

  .lst-kix_b6cuw8nv330h-8 > li {
    counter-increment: lst-ctn-kix_b6cuw8nv330h-8;
  }

  ol.lst-kix_b6cuw8nv330h-0.start {
    counter-reset: lst-ctn-kix_b6cuw8nv330h-0 0;
  }

  ol.lst-kix_orrx7eq3mcel-0 {
    list-style-type: none;
  }

  .lst-kix_3myohsi2w1ck-1 > li {
    counter-increment: lst-ctn-kix_3myohsi2w1ck-1;
  }

  ol.lst-kix_3myohsi2w1ck-5.start {
    counter-reset: lst-ctn-kix_3myohsi2w1ck-5 0;
  }

  ol.lst-kix_3myohsi2w1ck-8.start {
    counter-reset: lst-ctn-kix_3myohsi2w1ck-8 0;
  }

  ol.lst-kix_3myohsi2w1ck-1.start {
    counter-reset: lst-ctn-kix_3myohsi2w1ck-1 0;
  }

  .lst-kix_b6cuw8nv330h-1 > li {
    counter-increment: lst-ctn-kix_b6cuw8nv330h-1;
  }

  ol.lst-kix_orrx7eq3mcel-0.start {
    counter-reset: lst-ctn-kix_orrx7eq3mcel-0 0;
  }

  .lst-kix_orrx7eq3mcel-1 > li:before {
    content: 'o  ';
  }

  .lst-kix_orrx7eq3mcel-0 > li:before {
    content: '' counter(lst-ctn-kix_orrx7eq3mcel-0, lower-latin) '. ';
  }

  ol.lst-kix_b6cuw8nv330h-8.start {
    counter-reset: lst-ctn-kix_b6cuw8nv330h-8 0;
  }

  .lst-kix_3myohsi2w1ck-4 > li {
    counter-increment: lst-ctn-kix_3myohsi2w1ck-4;
  }

  .lst-kix_3myohsi2w1ck-8 > li:before {
    content: '' counter(lst-ctn-kix_3myohsi2w1ck-8, lower-roman) '. ';
  }

  .lst-kix_3myohsi2w1ck-7 > li:before {
    content: '' counter(lst-ctn-kix_3myohsi2w1ck-7, lower-latin) '. ';
  }

  .lst-kix_3myohsi2w1ck-5 > li:before {
    content: '(' counter(lst-ctn-kix_3myohsi2w1ck-5, lower-roman) ') ';
  }

  .lst-kix_3myohsi2w1ck-6 > li:before {
    content: '' counter(lst-ctn-kix_3myohsi2w1ck-6, decimal) '. ';
  }

  .lst-kix_orrx7eq3mcel-4 > li:before {
    content: 'o  ';
  }

  .lst-kix_orrx7eq3mcel-6 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_orrx7eq3mcel-5 > li:before {
    content: '\0025aa  ';
  }

  .lst-kix_b6cuw8nv330h-6 > li {
    counter-increment: lst-ctn-kix_b6cuw8nv330h-6;
  }

  .lst-kix_b6cuw8nv330h-2 > li {
    counter-increment: lst-ctn-kix_b6cuw8nv330h-2;
  }

  .lst-kix_3myohsi2w1ck-2 > li:before {
    content: '' counter(lst-ctn-kix_3myohsi2w1ck-2, lower-latin) '. ';
  }

  .lst-kix_orrx7eq3mcel-2 > li:before {
    content: '\0025aa  ';
  }

  .lst-kix_3myohsi2w1ck-3 > li:before {
    content: '(' counter(lst-ctn-kix_3myohsi2w1ck-3, decimal) ') ';
  }

  .lst-kix_3myohsi2w1ck-4 > li:before {
    content: '(' counter(lst-ctn-kix_3myohsi2w1ck-4, lower-latin) ') ';
  }

  .lst-kix_orrx7eq3mcel-3 > li:before {
    content: '\0025cf  ';
  }

  ol.lst-kix_b6cuw8nv330h-1.start {
    counter-reset: lst-ctn-kix_b6cuw8nv330h-1 0;
  }

  ol.lst-kix_3myohsi2w1ck-4.start {
    counter-reset: lst-ctn-kix_3myohsi2w1ck-4 0;
  }

  .lst-kix_3myohsi2w1ck-3 > li {
    counter-increment: lst-ctn-kix_3myohsi2w1ck-3;
  }

  ol.lst-kix_3myohsi2w1ck-7.start {
    counter-reset: lst-ctn-kix_3myohsi2w1ck-7 0;
  }

  .lst-kix_3myohsi2w1ck-0 > li {
    counter-increment: lst-ctn-kix_3myohsi2w1ck-0;
  }

  ol.lst-kix_b6cuw8nv330h-8 {
    list-style-type: none;
  }

  ol.lst-kix_b6cuw8nv330h-2.start {
    counter-reset: lst-ctn-kix_b6cuw8nv330h-2 0;
  }

  ol.lst-kix_b6cuw8nv330h-3 {
    list-style-type: none;
  }

  ol.lst-kix_b6cuw8nv330h-2 {
    list-style-type: none;
  }

  ol.lst-kix_b6cuw8nv330h-1 {
    list-style-type: none;
  }

  .lst-kix_orrx7eq3mcel-8 > li:before {
    content: '\0025aa  ';
  }

  ol.lst-kix_b6cuw8nv330h-0 {
    list-style-type: none;
  }

  ol.lst-kix_b6cuw8nv330h-7 {
    list-style-type: none;
  }

  .lst-kix_b6cuw8nv330h-8 > li:before {
    content: '' counter(lst-ctn-kix_b6cuw8nv330h-8, lower-roman) '. ';
  }

  ol.lst-kix_b6cuw8nv330h-6 {
    list-style-type: none;
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  ol.lst-kix_b6cuw8nv330h-5 {
    list-style-type: none;
  }

  .lst-kix_b6cuw8nv330h-7 > li:before {
    content: '' counter(lst-ctn-kix_b6cuw8nv330h-7, lower-latin) '. ';
  }

  .lst-kix_orrx7eq3mcel-7 > li:before {
    content: 'o  ';
  }

  ol.lst-kix_b6cuw8nv330h-4 {
    list-style-type: none;
  }

  ol.lst-kix_3myohsi2w1ck-0 {
    list-style-type: none;
  }

  .lst-kix_b6cuw8nv330h-6 > li:before {
    content: '' counter(lst-ctn-kix_b6cuw8nv330h-6, decimal) '. ';
  }

  .lst-kix_orrx7eq3mcel-0 > li {
    counter-increment: lst-ctn-kix_orrx7eq3mcel-0;
  }

  .lst-kix_3myohsi2w1ck-8 > li {
    counter-increment: lst-ctn-kix_3myohsi2w1ck-8;
  }

  ol.lst-kix_3myohsi2w1ck-3.start {
    counter-reset: lst-ctn-kix_3myohsi2w1ck-3 0;
  }

  .lst-kix_b6cuw8nv330h-5 > li:before {
    content: '' counter(lst-ctn-kix_b6cuw8nv330h-5, lower-roman) '. ';
  }

  .lst-kix_3myohsi2w1ck-5 > li {
    counter-increment: lst-ctn-kix_3myohsi2w1ck-5;
  }

  .lst-kix_b6cuw8nv330h-2 > li:before {
    content: '' counter(lst-ctn-kix_b6cuw8nv330h-2, lower-roman) '. ';
  }

  ol.lst-kix_b6cuw8nv330h-5.start {
    counter-reset: lst-ctn-kix_b6cuw8nv330h-5 0;
  }

  .lst-kix_b6cuw8nv330h-7 > li {
    counter-increment: lst-ctn-kix_b6cuw8nv330h-7;
  }

  .lst-kix_b6cuw8nv330h-4 > li:before {
    content: '' counter(lst-ctn-kix_b6cuw8nv330h-4, lower-latin) '. ';
  }

  .lst-kix_b6cuw8nv330h-4 > li {
    counter-increment: lst-ctn-kix_b6cuw8nv330h-4;
  }

  ol.lst-kix_3myohsi2w1ck-0.start {
    counter-reset: lst-ctn-kix_3myohsi2w1ck-0 0;
  }

  .lst-kix_b6cuw8nv330h-3 > li:before {
    content: '' counter(lst-ctn-kix_b6cuw8nv330h-3, decimal) '. ';
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c6 {
    margin-left: 18pt;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c7 {
    margin-left: 36pt;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c4 {
    margin-left: 54pt;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c3 {
    margin-left: 36pt;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Calibri', sans-serif;
    font-style: normal;
  }

  .c10 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Arial', sans-serif, sans-serif;
    font-style: normal;
  }

  .c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Calibri', sans-serif;
    font-style: normal;
  }

  .c11 {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c0 {
    padding-top: 0;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c16 {
    padding-top: 0;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: center;
  }

  .c15 {
    padding-top: 0;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c8 {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c14 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 28pt;
    font-style: normal;
  }

  .c17 {
    background-color: #ffffff;
  }

  .c12 {
    font-weight: 400;
    font-family: 'Calibri', sans-serif;
  }

  .c2 {
    padding: 0;
    margin: 0;
  }

  .c9 {
    font-weight: 700;
    font-family: 'Calibri', sans-serif;
  }

  .c13 {
    font-style: italic;
  }

  .title {
    padding-top: 0;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: 'Arial', sans-serif, sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 0;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial', sans-serif;
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial', sans-serif;
  }

  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
