.stepper-container {
  height: auto;
  display: flex;

  .titles-container {
    display: grid;
    grid-gap: 60px;
    margin-right: 24px;

    .titles {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      color: #393a42;

      &.confirmed {
        color: #a2aab6;
      }

      .title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: right;

        width: 100%;
        justify-content: flex-end;
      }

      .description {
        position: absolute;
        top: 28px;
        font-family: 'Sofia W05 Light', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: right;
        justify-content: flex-end;
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  .stepper-wrapper {
    margin-top: 5px;
  }
}

.MuiStep-root.MuiStep-vertical.Mui-completed {
  width: 18px;
  height: 18px;
}
.MuiStepLabel-iconContainer {
  padding: 0;

  &.Mui-completed {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #a2aab6;

    .CustomStepperIcon-completedIcon {
      color: #ffffff;
      width: 12px;
      height: 12px;
    }
  }

  .CustomStepperIcon-active {
    background: transparent;
    border: 2px solid #deb900;
    width: 18px;
    height: 18px;
    border-radius: 100%;
  }

  .CustomStepperIcon-circle {
    background: transparent;
    border: 2px solid #a2aab6;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-bottom: 3px;
  }
}

.MuiStep-vertical {
  display: flex;
  justify-content: center;
}

.MuiStepLabel-iconContainer.Mui-completed {
  padding: 0;
}

.MuiStepLabel-root.MuiStepLabel-vertical {
  padding: 0;
  display: flex;
}

.MuiStepConnector-root.MuiStepConnector-vertical {
  margin-left: 8px;
  padding: 4px 0 8px 0;

  .MuiStepConnector-line.MuiStepConnector-lineVertical {
    display: block;
    min-height: 24px;
    width: 2px;
    background: #bdbdbd;
    height: 100%;
  }
}
