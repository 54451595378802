@import '../../styles/globals.scss';

.change-password-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  margin: 0px auto;

  @media screen and (max-width: $view-sm) {
    margin: 100px 0 180px 0;
  }

  @media screen and (max-width: $view-xs) {
    width: $width-mobile;
  }
}

.change-password-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Sofia W05 Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #393a42;

  .form-inputs {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
