.edits-form {
  .array-buttons-container {
    margin-top: 16px;
    display: flex;
    flex-direction: row-reverse;

    .add-field-button {
      border: 1px solid rgba(46, 125, 50, 0.5);
      border-radius: 200px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 23px;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      color: #2e7d32;
    }
  }

  .MuiFormControl-root {
    margin-bottom: 8px;
  }

  .section-sub-title {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
  }

  .MuiChip-root {
    background-color: #deb900;
  }

  .save-button-container {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
}

.admin-grid-dashboard {
  .table-grid-item {
    .ann-form {
      .form-input {
        margin-bottom: 20px;
      }
    }
  }
}
