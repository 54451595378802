@import './src/styles/globals';

$sub-footer-margin: 25px;

.sub-footer {
  position: relative;
  width: 100%;
  height: 304px;
  background-color: #f5f6ff;
  margin-top: auto;

  @media screen and (max-width: $view-lg) {
    height: auto;
  }
}

.sub-footer-container {
  height: 100%;
  display: flex;
  padding: 55px 100px;
  justify-content: space-between;

  @media screen and (max-width: $view-sm) {
    padding: 20px;
  }

  @media screen and (max-width: $view-lg) and (min-width: $view-sm) {
    flex-wrap: wrap;
    padding: 40px;
  }

  @media screen and (max-width: $view-md) {
    flex-direction: column;
  }

  .for-routes-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (min-width: $view-lg) {
      display: none;
    }

    @media screen and (max-width: $view-md) {
      margin: 0 0 $sub-footer-margin 0;
    }

    section {
      @media screen and (max-width: $view-md) {
        flex: 1;
      }
    }
  }

  .routes-container {
    color: $darkGrey;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media screen and (max-width: $view-md) {
      flex: none;
      margin: 0 0 $sub-footer-margin 0;
    }

    div {
      font-size: 24px;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin: 5px 0 0 0;
      padding: 0;

      li {
        font-family: 'Sofia W05 Light', sans-serif;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #393a42;
      }
    }
  }

  // FIXME: This is a hack to make it easier to make it responsive
  // according to the design
  #routes-container-for-students {
    @media screen and (max-width: $view-md) {
      margin-right: 10vw;
    }
  }
}

.routes-minite-list {
  @media screen and (max-width: $view-md) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .routes-minite-list-2 {
    @media screen and (min-width: $view-md) {
      // margin-top: 12px !important; // FIXME: Don't use important
    }
  }

  ul {
    @media screen and (max-width: $view-md) {
      flex: 1;
    }
  }
}

.sub-footer-divider {
  display: none;

  @media screen and (max-width: $view-md) {
    display: initial;
  }
}

.newsletter-container {
  @media screen and (max-width: $view-md) {
    width: 100%;
    margin: $sub-footer-margin 0 0 0;
  }

  .newsletter-textfield-container {
    width: 241px;

    @media screen and (max-width: $view-md) {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.newsletter-text {
  display: flex;
  margin-bottom: 17px;

  @media screen and (max-width: $view-md) {
    display: none;
  }
}

.newsletter-text,
.social-email {
  font-family: 'Sofia W05 Light', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  align-items: center;
  color: #393a42;
}

.connect-with-us {
  margin-bottom: 65px;

  #section-title-connect {
    display: initial;

    @media screen and (max-width: $view-md) {
      display: none;
    }
  }
}

.social-medias {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 20px 0 6px 0;

  .social-media {
    cursor: pointer;
  }
}

.social-email {
  font-family: 'Sofia W05 Light', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #393a42;
}

.cat-icon-container {
  width: 64px;
  position: absolute;
  bottom: -5px;
  left: 80px;

  @media screen and (max-width: $view-md) {
    left: 0;
  }
}

.section-title {
  font-family: 'Sofia W05 Medium', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  display: flex;
  align-items: center;
  color: #393a42;
}

.language-switcher {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}
