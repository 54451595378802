.deactivation {
  width: fit-content;

  .deactivate-button {
    font-weight: 700;
    font-size: 13px;
    line-height: 23px;
    color: #d32f2f;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
  }
}

.generic-modal {
  .button.warning.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
}
