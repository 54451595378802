.proposal-student-container {
  max-width: 483px;

  p {
    margin: 0;
  }

  .proposal-student-header {
    padding: 24px 24px 12px 24px;
    height: 164px;
    background: linear-gradient(
        0deg,
        rgba(222, 185, 0, 0.3),
        rgba(222, 185, 0, 0.3)
      ),
      #ffffff;

    .close-icon-container {
      display: flex;
      justify-content: flex-end;

      .close-drawer-icon {
        font-size: 21px;
        cursor: pointer;
      }
    }

    .title-container {
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding-bottom: 21px;

      .title {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        color: #393a42;
      }
    }
  }

  .proposal-student-content {
    padding: 12px 0 0 0;
    margin: 0 16px;

    .job-header {
      border-bottom: 1px solid #e1e1e1;

      .job-titles {
        display: flex;
        justify-content: space-between;

        .job-company-name-container {
          display: flex;
          gap: 12px;
          align-items: center;

          .job-avatar-image {
            width: 100px;
            height: auto;
          }

          .titles-container {
            .job-title {
              font-family: 'Sofia W05 Semi Bold', sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 26px;
              color: #393a42;
              margin: 0 0 12px 0;
            }

            .company-title {
              font-family: 'Sofia W05 Medium', sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.1px;
              color: #393a42;
              margin: 0;
            }
          }
        }
      }

      .job-header-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 28px;
        padding: 22px 0 18px 0;

        .button {
          padding: 0;
          font-family: 'Sofia W05 Semi Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: #deb900;
          cursor: pointer;
        }
      }
    }

    .proposal-info {
      display: flex;
      justify-content: space-between;
    }

    .proposal-info-container {
      margin-top: 16px;

      .title {
        margin-bottom: 24px;
      }

      .info-rows {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .info-row {
          display: flex;
          flex-wrap: nowrap;

          span,
          p {
            flex: 1 1 50%;
          }
        }
      }

      .accordions-container {
        margin-top: 38px;
        display: flex;
        flex-direction: column;
        gap: 38px;
        .question-accordion {
          padding: 0;
        }

        .css-15v22id-MuiAccordionDetails-root {
          padding: 0;
        }

        .proposal-question {
          font-family: 'Sofia W05 Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.001em;
          color: #393a42;
        }

        .proposal-answer {
          font-family: 'Sofia W05 Regular', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #393a42;
          border-bottom: 1px solid #e1e1e1;
          padding: 0 0 16px 0;
        }
      }

      .proposal-action-container {
        margin-top: 48px;

        .title {
          font-family: 'Sofia W05 Semi Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 26px;
          color: #393a42;
          padding: 0;
          margin-bottom: 26px;
        }

        .action-list {
          .action-label {
            font-family: 'Sofia W05 Regular', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #393a42;
            padding-left: 15px;
          }
        }
      }
    }
  }

  .proposal-student-footer {
    margin-top: 60px;
    padding: 16px 24px;
    border-top: 1px solid #e1e1e1;

    .drawer-buttons {
      display: flex;
      justify-content: space-between;

      .proposal-actions {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        width: 100%;
      }

      .button {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        border-radius: 4px;

        &.retract {
          background: #d32f2f;
          color: #ffffff;

          &.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12);
          }
        }

        &.sign {
          width: 165px;
          height: 42px;
          background: #14db87;
          border-radius: 4px;
          color: #393a42;
        }
      }
    }
  }
}
