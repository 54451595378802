.talents-section {
  background: #ffffff;

  .talents-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    display: flex;
    align-items: flex-end;
    color: #ffffff;
    margin: 0;
  }
}

.pool-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}
