@import '../../styles/globals.scss';

.dashboard-container {
  p {
    padding: 0;
    margin: 0;
  }

  .dashboard-refer-card {
    border-radius: 25px;
    margin-bottom: 25px;
    padding: 15px;
    cursor: pointer;
    background: #ed621e;

    .ref-card-title {
      font-family: 'Sofia W05 Bold', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.001em;
      color: white;
      line-height: 1;
      margin-bottom: 8px;

      @media screen and (max-width: $view-sm) {
        font-size: 17px;
      }
    }

    .ref-card-text {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      color: white;
      line-height: 1;

      @media screen and (max-width: $view-sm) {
        font-size: 14px;
      }
    }

    .copy-ref {
      width: 20px;
      height: 20px;
      position: relative;
      top: 4px;
      cursor: pointer;
      transform: scaleX(-1);
      margin-left: 5px;
      word-break: break-all;
    }

    .ref-code {
      text-decoration: underline;
      font-family: 'Sofia W05 Bold', sans-serif;
    }
  }

  .dashboard-title {
    display: flex;
    gap: 8px;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #393a42;

    .verified-user-icon {
      width: 20px;
      height: 21px;
      color: #1976d2de;
    }

    .unverified-user-tag {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.1px;
      color: #e02727;
    }
  }

  .dashboard-greeting {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #393a42;
    margin-top: 8px;
  }

  .dashboard-pie-reminder-container {
    margin-top: 32px;
    display: flex;
    gap: 16px;
    align-items: center;

    @media all and (max-width: 600px) {
      flex-wrap: wrap;
    }

    .dashboard-pie-container {
      margin-right: 16px;
    }

    .reminders-list {
      grid-gap: 24px;
      overflow-x: scroll;
      overflow-y: hidden;
      width: 100%;
      margin-bottom: 32px;
    }

    @media all and (max-width: 600px) {
      .reminders-list {
        grid-gap: 16px;
        width: 97vw;
      }
    }
  }

  .dashboard-statistic-cards {
    grid-gap: 24px;
    overflow-x: auto;
    overflow-y: hidden;

    @media all and (max-width: 600px) {
      width: 97vw;
    }
  }

  @media all and (max-width: 600px) {
    .dashboard-statistic-cards {
      grid-gap: 16px;
    }
  }

  .dashboard-knowledge-container {
    padding: 32px 24px;
    margin-top: 32px;
    margin-bottom: 88px;
    width: 100%;
    height: 214px;
    background: #ffffff;
    border-top: 1px solid #dddddd;

    .base-container {
      max-width: 320px;
      cursor: pointer;
      width: 100%;

      .base-image-container {
      }

      .base-title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: #393a42;
        margin-top: 8px;
      }

      .base-text-link {
        display: flex;
        flex-direction: row;
        gap: 37px;
        margin-top: 8px;

        .base-text {
          font-family: 'Sofia W05 Regular', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #393a42;
        }
      }
    }
  }

  .link {
    color: $lightBlue;
  }

  .no-data {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 238px;
    background: #f9f9f9;
    padding: 15%;

    @media all and (max-width: 600px) {
      flex-direction: column;
      padding: 10px;
    }

    .message {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #4e5b73;
    }
  }

  .dashboard-recommendations-container {
    .recommendations-title {
      margin-bottom: 32px;
      margin-top: 34px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
    }

    .dashboard-recommendations-cards {
      display: flex;
      flex-direction: row;
      overflow-x: auto;

      @media all and (max-width: 600px) {
        width: 97vw;
      }
    }
  }

  .dashboard-role-models-container {
    .role-models-title {
      margin-bottom: 32px;
      margin-top: 34px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
    }

    .dashboard-role-models-cards {
      display: flex;
      flex-direction: row;
      overflow-x: auto;

      @media all and (max-width: 600px) {
        width: 97vw;
      }
    }
  }
}

.fav-container-small {
  max-width: 100vw;
}
