.review-modal {
  .content-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #393a42;

    .MuiIconButton-root {
      padding: 0;
    }
  }

  .rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Sofia W05 Regular', sans-serif;

    .info-wrapper {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: #393a42;
      }

      .date {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #393a42;
        opacity: 0.5;
        margin-top: 2px;
      }
    }
  }

  .review-block {
    border-bottom: 1px solid #e1e1e1;
    padding: 16px 0;

    .description {
      margin-top: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: block;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
