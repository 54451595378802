.event {
  display: flex;
  justify-content: space-between;
}

.event-log-detail {
  padding-top: 32px;
  padding-bottom: 32px;
}

.events-filter-wrapper {
  display: flex;
  gap: 16px;

  button {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    color: #393a42;
  }
}
