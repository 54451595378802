.admin-grid-dashboard {
  .section-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #000000;
  }

  .admin-statistics-container {
    margin-top: 24px;
    display: flex;
    gap: 24px;
    flex-direction: row;
  }

  .admin-smaller-statistics {
    margin-top: 0px;
    gap: 24px;
  }

  .grid-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 18px;
    max-width: 900px;

    .section-sub-title {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: #000000;
    }

    .admin-table-inputs {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .admin-search-input {
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #393a42;
      }

      .admin-filter-table-button {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        color: #393a42;
      }
    }
  }
}
