#date_one_input-wrapper,
#date_two_input-wrapper {
  visibility: hidden;
  height: 1px;
}

.MuiFormControl-root.date-picker-form-control {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.click-text {
  color: #1976d2;
  cursor: pointer;
  margin: 0;
}

.request-interview-form {
  display: flex;
  flex-direction: column;
}

.request-interview-modal {
  .content-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    padding: 16px 8px 16px 24px;
  }

  .content-text {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-size: 16px;
    color: #393a42;
    margin-bottom: 23px;
  }

  .button {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-size: 14px;

    &.agree {
      background-color: #deb900;
      width: auto;
    }

    &.Mui-disabled {
      background: rgba(0, 0, 0, 0.12);
    }
  }
}
