.statistic-card-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  align-items: flex-start;
  width: 207px;
  min-width: 184px;
  height: 136px;
  background: #ffffff;
  border: 1px solid #eedc80;
  border-radius: 4px;

  p {
    margin: 0;
    padding: 0;
  }

  .statistic-card-number {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    line-height: 76px;
    color: #deb900;
  }

  .statistic-card-text {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.001em;
    color: #46566e;
  }

  &.statistic-card-student {
    width: 185px;
  }
}
