@import './src/styles/globals';

#permanent-sidepanel {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.sidepanel {
  background-color: $white;
  min-height: 100%;
  border-right: 1px solid $divider;
  overflow-y: auto;

  .sidepanel-top {
    padding: 42px 0 0 16px;

    .sidepanel-top-avatar {
      width: 40px;
      height: 40px;
    }

    .sidepanel-top-name {
      margin: 30px 0 10px 0;
      color: $darkGrey;
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
    }
  }

  .sidepanel-bottom {
    padding: 0;
  }
}

.public-sidepanel {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .public-sidepanel-logo-container {
    img {
      width: 90px;
      height: auto;
    }
  }

  .public-sidepanel-routes-container {
    flex: auto;

    ul {
      list-style-type: none;
      font-family: 'Sofia W05 Light', sans-serif;
      font-style: normal;
      font-weight: 400;
      padding: 0;

      li {
        font-size: 16px;
        padding: 12px 0;
        cursor: pointer;

        &:hover {
          color: $yellow;
        }
      }
    }
  }

  .public-sidepanel-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 36px;
    gap: 10px;

    .yellow {
      color: $yellow;
    }

    .sidepanel-button {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.MuiListItemText-primary {
  font-family: 'Sofia W05 Medium', sans-serif;
}
