@import 'src/styles/globals';

.community-wrapper.sides {
  padding: 100px 48px 140px 48px;
  position: relative;
  background: #deb900;
  align-items: flex-start;
  justify-content: center;

  p {
    margin: 0 0 48px 0;
  }

  @media screen and (max-width: $view-md) {
    display: none;
  }

  .title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    width: 471px;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #ffffff;
  }

  .description {
    width: 408px;
    height: 110px;
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  .avatars-container {
    display: flex;
    gap: 24px;
    margin: 0;

    .MuiAvatarGroup-avatar {
      border: 2px solid transparent;
      width: 40px;
      height: 40px;
    }

    .join-text {
      width: 321px;
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin: 0;
    }
  }

  .cat-background {
    img {
      position: absolute;
      right: 0;
      width: 690px;
      height: 470px;
      bottom: 0;
    }
  }
}
