.talent-card-container {
  width: 31%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  font-family: 'Sofia W05 Regular', sans-serif;

  p {
    margin: 0;
  }

  .talent-avatar {
    width: 80px;
  }

  .talent-rate-wrapper {
    display: flex;
    flex-direction: column;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 4px 8px;

    .talent-rate-container {
      display: flex;
      flex-direction: row;
      gap: 10.5px;
      align-items: flex-end;
      text-transform: capitalize;

      .rate {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        padding-right: 8px;
        color: #deb900;
      }
    }

    .match-score-container {
      display: flex;
      gap: 10.5px;
      align-items: flex-start;
    }
  }

  .card-content {
    padding: 16px 24px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .student-study {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 26px;
      color: #393a42;
    }

    .student-info {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #393a42;
      margin: 24px 0 24px 0;
    }

    .language-list {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #393a42;
    }

    .student-skills {
      margin-top: 8px;
      gap: 8px;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .card-footer {
    border-top: 1px solid #e1e1e1;
    padding: 16px 24px;

    .proposal-button {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 48%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}
