.admin-statistic-card {
  box-sizing: border-box;
  padding: 16px;
  background: rgba(222, 185, 0, 0.05);
  border: 2px solid #eedc80;
  border-radius: 4px;
  width: fit-content;
  height: auto;
  min-height: 82px;
  display: flex;

  .admin-statistic-card-content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    &.column {
      flex-direction: column;
      align-items: flex-start;
    }

    .counter {
      font-family: 'Sofia W05 Bold', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 50px;
      color: #deb900;
    }

    .text {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #9b8100;
    }
  }
  .admin-smaller-statistics {
    padding: 5px;
    .counter {
      font-family: 'Sofia W05 Bold', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 31px;
      line-height: 30px;
      color: #deb900;
    }

    .text {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.1px;
      color: #9b8100;
    }
  }
  .admin-smaller-statistics {
    padding: 5px;
    .counter {
      font-family: 'Sofia W05 Bold', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 31px;
      line-height: 30px;
      color: #deb900;
    }

    .text {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.1px;
      color: #9b8100;
    }
  }
  .admin-smaller-statistics {
    padding: 5px;
    .counter {
      font-family: 'Sofia W05 Bold', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 31px;
      line-height: 30px;
      color: #deb900;
    }

    .text {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.1px;
      color: #9b8100;
    }
  }
}
