.student-sign-up-button {
  margin-top: 40px;
}

.student-sign-up {
  display: flex;
  justify-content: center;
}

.student-form {
  @media all and (max-width: 425px) {
    width: 90vw;
  }
}

.welcome-section {
  margin: 60px 0 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    p {
      margin: 0;
    }

    .title {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 360px;
      text-align: center;
      color: #393a42;
    }

    .description {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      max-width: 670px;
      text-align: center;
      color: #393a42;
    }
  }

  .sample-container {
    .title {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      text-align: center;
      color: #393a42;
    }
    .content {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      max-width: 670px;
      text-align: center;
      color: #393a42;
    }
  }
}
