.admin-dashboard-card {
  box-sizing: border-box;
  padding: 16px;
  width: auto;
  height: auto;
  background: rgba(222, 185, 0, 0.05);
  border: 2px solid #eedc80;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  min-width: 215px;

  &-header {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #393a42;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;

    .admin-dashboard-card-link {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 23px;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      color: #deb900;
    }
  }

  .admin-dashboard-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;

    .counter {
      font-family: 'Sofia W05 Bold', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 60px;
      line-height: 62px;
      color: #deb900;
    }

    .text {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #9b8100;
    }
  }
}
