.timesheet-status-chip {
  font-family: 'Sofia W05 Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1px;
  color: #ffffff;
  height: 24px;

  padding: 5px 12px 7px;
  gap: 10px;
  border-radius: 200px;

  &.draft {
    color: #393a42;
  }

  &.closed {
    color: #393a42;
    background-color: transparent;
    border: 1px solid red;
  }
}
