.company-talent-pool-container {
  margin: 0;

  .pool-header {
    margin-bottom: 48px;
    margin-top: 32px;
    padding-left: 64px;

    .pool-title {
      margin: 0 0 32px 0;
      display: flex;
      gap: 12px;
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 52px;
      align-items: center;
      color: #393a42;

      .pool-position {
        color: #deb900;
      }
    }

    .pool-description {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #393a42;
    }

    @media screen and (max-width: 550px) {
      padding-left: 0;

      .pool-title {
        display: inline;
        font-size: 24px;
        line-height: 26px;

        .pool-position {
          margin-left: 5px;
        }
      }

      .pool-description {
        margin-top: 32px;
      }
    }
  }

  .pool-students-cards {
    p {
      margin: 0;
    }

    margin: 48px 64px 24px 64px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .advice-matches {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
    color: #393a42;
  }

  .pool-results-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 23px 0;
    font-family: 'Sofia W05 Regular', sans-serif;
    font-size: 14px;
  }
}

.matches-pagination {
  display: flex;
  justify-content: center;
  margin: 48px 60px;
  padding: 0;

  @media screen and (max-width: 545px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.match-chips-row {
  display: flex;
  gap: 8px;
}

.favourite-pool-talent-cards {
  width: 100%;
  background: #deb900;
  padding: 24px 0;

  .favourites-title-container {
    height: 48px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
    padding-left: 64px;

    .favourites-title {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      display: flex;
      align-items: flex-end;
      color: #ffffff;
    }

    @media screen and (max-width: 426px) {
      padding-left: 10px;
    }
  }
}
.match-jobs {
  .no-matches {
    text-align: center;
  }

  .view-job {
    margin-bottom: 30px;
    font-family: 'Sofia W05 Semi Bold', sans-serif;

    &.view-job-no-matches {
      margin-top: 20px;
      max-width: 900px;
    }
  }
}
