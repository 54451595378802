.support-forms-container {
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #393a42;
  }

  .link {
    color: rgba(25, 118, 210, 0.87);
    text-decoration: underline;
  }

  .description-text {
    font-size: 16px;
    margin-top: 24px;
    font-family: 'Sofia W05 Regular', sans-serif;
  }

  .MuiFormControl-root {
    margin-top: 16px;
  }

  .confirm-button {
    margin: 40px 0 0 auto;
    width: fit-content;
  }
}
