.calendly-modal {
  .content-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    padding: 16px 8px 16px 24px;
  }

  .content-text {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-size: 16px;
    color: #393a42;
  }

  .callback-link {
    color: #1976d2;
  }

  .button {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-size: 14px;
    background-color: #deb900;
  }
}
