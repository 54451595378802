@import '../../../../../styles/globals.scss';

.welcome-section {
  margin: 60px 0 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    p {
      margin: 0;
    }

    .title {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 360px;
      text-align: center;
      color: #393a42;
    }

    .description {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      max-width: 670px;
      text-align: center;
      color: #393a42;
    }
  }

  .fee-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    p {
      margin: 0;
    }

    .title {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #393a42;
      margin-bottom: 24px;
    }
  }
}

.calendly-inline-widget {
  max-width: 794px;
  height: 690px;
  width: 100%;
}

.MuiButtonBase-root.welcome-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 200px;
  height: 42px;
  background: #deb900;
  border-radius: 4px;
  font-family: 'Sofia W05 Semi Bold', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #393a42;
  margin-top: 24px;
}

.MuiButtonBase-root.screening-button {
  width: 200px;
  margin: 15px auto;
  display: flex;
}

.url {
  color: $lightBlue;
}

@media (max-width: $view-xs) {
  .fee-container {
    width: 100%;
  }
}
