@import 'src/styles/globals';

.browse-job-container {
  .page-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #393a42;
    margin-bottom: 40px;
  }

  legend.MuiFormLabel-root {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.001em;
    color: #000000;
  }

  .filter-label.MuiFormControlLabel-root {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #393a42;
  }

  .MuiFormControl-root:not(:first-child) {
    margin-top: 41px;
  }

  .range-fields {
    margin-top: 31px;
    flex-wrap: nowrap;

    .MuiFormControl-root:not(:first-child) {
      margin-top: 0;
    }
  }
}

.browse-job-list {
  margin-top: 32px;
  grid-gap: 24px;
}

.pool-pagination {
  display: flex;
  justify-content: center;
  margin: 32px 0;
  padding: 32px 0 32px 0;
  border-top: 1px solid #dddddd;
}

@media screen and (max-width: 425px) {
  .MuiGrid-container.browse-job-container {
    width: 90vw;
  }
}
