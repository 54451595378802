.refer-friend-container {
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #393a42;
  }

  .link {
    color: rgba(25, 118, 210, 0.87);
  }

  .description-text {
    font-size: 16px;
    margin-top: 24px;
    font-family: 'Sofia W05 Regular', sans-serif;
    white-space: pre-line;

    &.item {
      margin-top: 10px;
    }
  }

  .bold-text {
    font-weight: bold;
    text-decoration: underline;

    &.code {
      text-decoration: none;
    }
  }

  .sub-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
  }
}
