@import 'src/styles/globals';

.createjob-container-parent {
  width: 100%;
  display: flex;
  justify-content: center;
}

.createjob-container {
  grid-gap: 100px;
  margin: 60px 0 120px 0;

  @media screen and (max-width: $view-md) {
    width: $width-mobile;
    flex-direction: column;
    gap: 5px;
    margin: 60px 0 30px 0;
  }
}

.jobtype-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 16px 0;
  padding: 24px 16px;
  width: 512px;
  border: 2px solid $yellow;
  font-style: normal;
  cursor: pointer;
  transition-duration: 200ms;

  @media screen and (max-width: $view-md) {
    width: 100%;
  }

  // FIXME: One lamp icon is bigger than the others.
  .jobtype-button-icon-container {
    padding: 0;
    margin-right: 24px;

    @media screen and (max-width: $view-sm) {
      margin-right: 12px;

      img {
        width: 60px;
      }
    }
  }

  .jobtype-button-title {
    margin: 0 0 5px 0;
    font-size: 1.2em;
    font-family: 'Sofia W05 Medium', sans-serif;
    color: $darkGrey;
  }

  .jobtype-button-description {
    margin: 0;
    font-family: 'Sofia W05 Light', sans-serif;
    font-size: 0.9em;
    line-height: 18px;
    color: $darkGrey;
  }
}

.createjob-content {
  width: 512px;

  @media screen and (max-width: $view-sm) {
    width: 100%;
  }

  .createjob-content-title {
    display: flex;
    flex-direction: column;
    font-family: 'Sofia W05 Medium', sans-serif;
    font-size: 1.5em;
    margin: 0 0 44px 0;
    gap: 12px;

    .content-desc {
      font-family: 'Sofia W05 Light', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #393a42;
    }
  }

  .createjob-content-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;

    .MuiButton-root {
      font-family: 'Sofia W05 Medium', sans-serif;
      width: auto;
      color: $black;
    }

    .MuiButton-contained {
      background-color: $yellow;
      color: $black;

      &.pay-button {
        background: #14db87;
        border-radius: 4px;
      }

      &.Mui-disabled {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    }

    .MuiButton-text:disabled {
      opacity: 0.26;
    }

    .MuiButton-text:disabled {
      opacity: 0.26;
    }

    .MuiButton-text:disabled {
      opacity: 0.26;
    }
  }
}

.createjob-notes {
  width: 300px;

  @media screen and (max-width: $view-lg) {
    width: 0%;
  }
}

.MuiMobileStepper-root {
  width: 100%;

  .MuiMobileStepper-progress {
    width: 100%;
    background-color: rgba(162, 170, 182, 0.3);
    overflow: hidden;
  }

  .MuiLinearProgress-bar {
    background-color: $yellow;
  }
}

.category-skeleton-wrapper {
  display: flex;

  .skeleton-item {
    height: 160px;
    width: 128px;
  }
}

.review-payment-form {
  .promo-check-text {
    font-family: 'Sofia W05 Light', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #393a42;
  }

  .promo-buttons {
    grid-gap: 12px;
    margin-bottom: 32px;

    @media screen and (max-width: $view-sm) {
      overflow-x: auto;
    }

    .promo-button {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 16px 12px 12px 12px;
      min-width: 286.5px;
      border: 2px solid #c7d2e2;

      .promo-button-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .promo-button-title {
          font-family: 'Sofia W05 Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.001em;
          color: #393a42;
        }

        .promo-button-description {
          padding: 0;
          text-align: center;
          font-family: 'Sofia W05 Regular', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #393a42;
          list-style: none;

          li {
            font-family: 'Sofia W05 Light', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #393a42;
          }
        }
      }

      .promo-count {
        .promo-count-text {
          font-family: 'Sofia W05 Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.1px;
          color: #deb900;
        }
      }

      .promo-button-icon-container {
        width: 40px;
        height: 40px;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .new-job-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #393a42;
    padding-bottom: 25px;
  }

  .payment-section {
    display: flex;
    flex-direction: column;
    width: 100%;

    .new-job-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 25px;

      .new-job-position-container {
        .new-job-position {
          margin: 0;
          display: flex;
          flex-direction: row;
          font-family: 'Sofia W05 Regular', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          align-items: center;
          letter-spacing: 0.001em;
          color: #393a42;
          margin-bottom: 6px;
        }

        .new-job-fee {
          font-family: 'Sofia W05 Light', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #393a42;
        }
      }

      .new-job-small-title {
        font-size: 18px;
      }
    }
  }

  .total-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .new-job-btw {
      border-top: 1px solid #c7d2e2;
    }

    .new-job-btw,
    .new-job-total {
      display: flex;
      gap: 32px;
      border: none;
    }

    .table-cells {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 1600;
      font-size: 16px;
      line-height: 26px;
      padding-right: 0;
      padding-left: 25px;
    }

    .total-cell {
      font-family: 'Sofia W05 Bold', sans-serif;
    }

    .left-cells {
      padding-right: 20px;
    }
  }
}

.describe-job-form {
  .sample-info {
    font-family: 'Sofia W05 Light', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }

  .add-question-container {
    .title {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #393a42;
    }

    .description-step,
    .sample-question-link {
      font-family: 'Sofia W05 Light', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    .description-step {
      color: #393a42;
    }

    .sample-question-link {
      color: #1976d2;
    }
  }
}

.student-level-form-tabs,
#student-level-tab-0,
#student-level-tab-1 {
  flex: 1;
  text-transform: none;
  height: 26px;
  font-family: 'Sofia W05 Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  &.Mui-selected {
    border-bottom-color: #deb900;
    color: #deb900;
  }
}

.posting-fee-info {
  margin-bottom: 16px;

  .info-title {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.001em;
  }

  .info-text {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.ideal-student-section,
.describe-job-section,
.review-payment-section {
  .section-title {
    p {
      margin: 0;
    }
  }

  .ideal-student-form,
  .describe-job-form {
    .form-inputs {
      .form-title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #393a42;
      }

      .helper-hint {
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #393a42;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin-top: 8px;
      }
    }

    .posting-fee-info {
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }
}

.createjob-form-section-container {
  margin: auto auto 30px auto;

  .createjob-subtitle {
    margin: 0;
    margin-top: 15px;
    font-size: 1.1em;
    font-family: 'Sofia W05 Light', sans-serif;
  }

  .createjob-grid-container {
    width: 700px;
    margin-top: 10px;

    @media screen and (max-width: $view-sm) {
      width: 92vw;
      overflow-x: scroll;
      height: 140px;
    }

    .MuiGrid-root {
      @media screen and (max-width: $view-sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }

  .createjob-category-button {
    border: 2px solid $yellow;
    width: 128px;
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    box-sizing: content-box;
    transition-duration: 200ms;

    .createjob-category-button-image {
      width: 40px;
    }

    .createjob-category-button-title {
      font-family: 'Sofia W05 Light', sans-serif;
      margin: 8px 10px 0 0;
    }
  }

  .createjob-form-inputs {
    width: 100%;
    margin-top: 10px;

    .job_start_at {
      margin-right: 20px;
    }

    @media screen and (max-width: $view-sm) {
      flex-direction: column;

      .job_start_at {
        margin-right: 0;
      }
    }

    .MuiFormControl-root {
      flex: 1;
    }

    .MuiButtonBase-root {
      padding: 0;

      @media screen and (max-width: $view-sm) {
        position: relative;
        right: 15px;
      }
    }
  }
}

.createjob-form-section-container {
  margin: auto auto 30px auto;

  .createjob-subtitle {
    margin: 0;
    margin-top: 15px;
    font-size: 1.1em;
    font-family: 'Sofia W05 Light', sans-serif;
  }

  .createjob-grid-container {
    width: 700px;
    margin-top: 10px;

    @media screen and (max-width: $view-sm) {
      width: 92vw;
      overflow-x: scroll;
      height: 140px;
    }

    .MuiGrid-root {
      @media screen and (max-width: $view-sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }

  .createjob-category-button {
    border: 2px solid $yellow;
    width: 128px;
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    box-sizing: content-box;
    transition-duration: 200ms;

    .createjob-category-button-image {
      width: 40px;
    }

    .createjob-category-button-title {
      font-family: 'Sofia W05 Light', sans-serif;
      margin: 8px 0 0 0;
    }
  }

  .createjob-form-inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .job_start_at {
      margin-right: 20px;
    }

    @media screen and (max-width: $view-sm) {
      flex-direction: column;

      .job_start_at {
        margin-right: 0;
      }
    }

    .MuiFormControl-root {
      flex: 1;
    }

    .MuiButtonBase-root {
      padding: 0;

      @media screen and (max-width: $view-sm) {
        position: relative;
        right: 15px;
      }
    }
  }
}

.createjob-form-section-container {
  margin: auto auto 30px auto;

  .createjob-subtitle {
    margin: 0;
    margin-top: 15px;
    font-size: 1.1em;
    font-family: 'Sofia W05 Light', sans-serif;
  }

  .smallerSubtitle {
    font-size: 1em;
    margin-top: 10px;
  }

  .createjob-grid-container {
    width: 700px;
    margin-top: 10px;

    @media screen and (max-width: $view-sm) {
      width: 92vw;
      overflow-x: scroll;
      height: 140px;
    }

    .MuiGrid-root {
      @media screen and (max-width: $view-sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }

  .createjob-category-button {
    border: 2px solid $yellow;
    width: 128px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    box-sizing: content-box;
    transition-duration: 200ms;
    font-size: 90%;

    .createjob-category-button-image {
      width: 40px;
    }

    .createjob-category-button-title {
      font-family: 'Sofia W05 Light', sans-serif;
      margin: 0;
    }
  }

  .createjob-form-inputs {
    width: 100%;
    margin-top: 10px;

    .job_start_at {
      margin-right: 20px;
    }

    @media screen and (max-width: $view-sm) {
      flex-direction: column;

      .job_start_at {
        margin-right: 0;
      }
    }

    .MuiFormControl-root {
      flex: 1;
    }

    .MuiButtonBase-root {
      padding: 0;

      @media screen and (max-width: $view-sm) {
        position: relative;
        right: 15px;
      }
    }
  }
}

.promo-button-subtitle {
  margin: -5px 0 10px;
  font-family: 'Sofia W05 Light', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #393a42;

  .bold-text {
    font-weight: bold;
  }
}

.user-agreement {
  margin-top: 35px;

  .MuiFormControlLabel-root {
    align-items: flex-start;

    .MuiCheckbox-root {
      padding: 0 19px 0 0;
    }

    .error {
      color: #d32f2f;
    }
  }

  .user-agreement-text {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #393a42;

    .learn-more-link {
      color: #1976d2;
    }
  }
}
