.minite-alert-title {
  font-family: 'Sofia W05 Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.minite-alert-text {
  font-family: 'Sofia W05 Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
