.student-scheduler-call {
  .MuiAlert-message {
    flex: 1;
  }

  .info-title {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.001em;
  }

  .info-text {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 28px;
  }
  .actions {
    flex: 1;
  }
}
