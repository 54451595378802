@import '../../styles/globals.scss';

.job-details-company-view-container {
  width: 100%;

  .spinner-overlay {
    position: fixed;
    width: '100%';
    height: '100%';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p,
  h2,
  h3,
  h4 {
    margin: 8px 0;
  }

  .job-details {
    padding: 39px 49px;

    @media screen and (max-width: $view-sm) {
      padding: 16px 20px;
    }
  }

  .job-title {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .location {
    text-transform: capitalize;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
  }

  .description {
    max-width: 80%;
  }

  .main {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;

    margin-bottom: 20px;

    .left {
      flex: 2;
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex: 1;

      @media screen and (max-width: $view-sm) {
        align-items: flex-start;
      }
    }

    @media screen and (max-width: $view-sm) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  .student-type {
    text-transform: capitalize;
    border-color: #1976d2;
    color: #1976d2;
  }

  .job-status {
    text-transform: capitalize;
  }

  .skill {
    margin-right: 10px;

    background-color: #f2f9fc;
    color: #393a42;
  }

  .address-info {
    text-align: right;

    @media screen and (max-width: $view-sm) {
      text-align: left;
    }
  }

  .info-title {
    font-size: 16px;
    color: #4e5b73;
  }

  .info-value {
    font-size: 16px;
    color: hsl(233, 7%, 24%);
  }

  .budget-container {
    margin-bottom: 17px;
  }

  .budget {
    border-bottom: 3px solid #deb900;
    font-size: 20px;
  }

  .proposals {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;

    .proposal-tabs {
      .proposal-tab {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #393a42;
        opacity: 0.5;
        text-transform: capitalize;

        &.selected {
          color: #deb900;
        }
      }

      .MuiTabs-indicator {
        height: 1px;
        background-color: #deb900;
      }
    }
  }

  .placeholder {
    color: #a8aab0;
    text-align: center;
    width: 60%;
    display: flex;
    align-self: center;

    padding: 20px 15px;
  }
}

.actions-container {
  &.modal {
    position: fixed;
    bottom: 0;
    background-color: white;
    height: auto;
    width: 100%;
    padding: 16px;
    border: none;

    .modal-top {
      display: flex;
      justify-content: space-between;
    }
  }

  padding: 26px 16px;
  border-left: 1px solid #e1e1e1;
  height: 100%;

  .title {
    font-size: 22px;
  }

  .action {
    display: flex;
    align-items: center;

    font-size: 16px;

    padding: 10px 0;
    text-align: left;

    .icon {
      margin-right: 10px;
    }

    .close {
      color: #ea4335;
    }

    .completed {
      color: $green;
    }
  }
}

.actions-container,
.no-border {
  border-left: 0px;
}

.heading-text {
  font-family: 'Sofia W05 Semi Bold', sans-serif;
  font-style: normal;

  &.job-type-title {
    text-transform: capitalize;
    margin-top: 0;
  }

  &.lower {
    text-transform: lowercase;
  }
}

.regular-text {
  font-family: 'Sofia W05 Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.medium-text {
  font-family: 'Sofia W05 Medium', sans-serif;
  font-weight: 500;
  word-break: break-word;
}

.job-drawer-company--wrapper {
  .MuiPaper-elevation {
    width: 100%;
    max-width: 483px;
  }
}

.job-details-description {
  word-wrap: break-word;
  color: #4e5b73;
  font-family: Sofia W05 Regular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 600px;
  white-space: pre-wrap;
}
