@import '../../styles/globals.scss';

.forbidden-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 100px;

  .MuiTypography-h1 {
    font-family: 'Sofia W05 Bold', sans-serif;
    color: #dbe2ed;
    font-size: 100px;
  }

  .MuiTypography-h6 {
    font-family: 'Sofia W05 Bold', sans-serif;
    color: #deb900;
    font-weight: bold;
    font-size: 30px;
  }

  @media screen and (max-width: $view-sm) {
    .MuiTypography-h1 {
      font-size: 45px;
    }

    .MuiTypography-h6 {
      font-size: 20px;
      text-align: center;
    }
  }
}
