.numberInput {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.flex-end {
  justify-content: flex-end !important;
}

.payment-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  .new-job-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 25px;

    .new-job-position-container {
      .new-job-position {
        margin: 0;
        display: flex;
        flex-direction: row;
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        letter-spacing: 0.001em;
        color: #393a42;
        margin-bottom: 6px;
      }

      .new-job-fee {
        font-family: 'Sofia W05 Light', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #393a42;
      }
    }

    .new-job-small-title {
      font-size: 18px;
    }
  }
}

.total-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .new-job-btw {
    border-top: 1px solid #c7d2e2;
  }

  .new-job-btw,
  .new-job-total {
    display: flex;
    gap: 32px;
    border: none;
  }

  .table-cells {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 1600;
    font-size: 16px;
    line-height: 26px;
    padding-right: 0;
    padding-left: 25px;
  }

  .total-cell {
    font-family: 'Sofia W05 Bold', sans-serif;
  }

  .left-cells {
    padding-right: 20px;
  }
}
