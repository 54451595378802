.account-financial-page {
  box-sizing: border-box;

  .account-financial-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #393a42;
  }
}
