.job-card-container {
  border: 1px solid #dadde2;
  width: calc(50% - 12px);
  background: #ffffff;
  font-family: 'Sofia W05 Regular', sans-serif;

  @media all and (max-width: 899px) {
    width: 100%;
  }
  p {
    margin: 0;
  }

  .job-card-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 0 24px;
    height: 118px;

    @media all and (max-width: 1055px) {
      padding-left: 5px;
    }

    .job-avatar-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-transform: capitalize;
    }

    .align-flex-right {
      display: flex;
      justify-content: flex-end;
    }
  }

  .job-card-content {
    padding: 8px 24px 0;
    .job-title-container {
      display: flex;
      flex-direction: column;
      gap: 10.5px;

      .job-title {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;
        color: #393a42;

        .pin-icon {
          transform: rotate(-45deg);
          color: #3bb142;
          margin-right: 5px;
        }
      }

      .rate-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .rate {
          display: flex;
          align-items: center;
          color: #deb900;

          .rate-number {
            font-family: 'Sofia W05 Regular', sans-serif;
            font-size: 14px;
            line-height: 18px;
          }
        }

        .rate-title {
          font-family: 'Sofia W05 Medium', sans-serif;
          font-size: 14px;
          line-height: 16px;
          color: #393a42;
        }
      }
    }

    .job-description-container {
      padding: 28px 0 24px 0;

      .job-description {
        font-family: 'Sofia W05 Regular', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #393a42;
        max-width: 400px;
        width: auto;
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .job-languages {
      .language-list {
        font-size: 16px;
        line-height: 20px;
        color: #393a42;

        .label {
          font-family: 'Sofia W05 Semi Bold', sans-serif;
        }

        .value {
          font-family: 'Sofia W05 Regular', sans-serif;
        }
      }
    }

    .job-tags {
      .job-tags-list {
        margin-top: 8px;
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  .job-card-footer {
    border-top: 1px solid #e1e1e1;
    margin-top: 24px;
    padding: 16px 24px;

    .job-card-buttons {
      justify-content: center;

      & > div {
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    #add-favourites-button {
      white-space: nowrap;
    }
  }

  &.job-matched {
    opacity: 0.6;
  }

  &.pinned-job {
    border: 1px solid #3bb142;
  }
}
