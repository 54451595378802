.admin-overview-page {
  .tabs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .section-titles-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .section-title {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      color: #393a42;
      margin: 0;
    }

    .section-sub-title {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #393a42;
      margin-bottom: 0;
    }
  }

  .callback-request-alert {
    width: 520px;
    height: 52px;

    .callback-alert-content {
      display: flex;
      gap: 32px;
      align-items: center;

      .info-text {
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }

      .info-button {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 23px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        padding: 0;
      }
    }
  }

  .overview-tabs {
    .overview-page-tab {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      text-transform: uppercase;
      font-variant: small-caps;
      color: #393a42;
      opacity: 0.5;

      &.Mui-selected {
        border-bottom-color: #deb900;
        color: #deb900;
      }
    }

    .MuiTabs-indicator {
      height: 1px;
      background-color: #deb900;
    }
  }
}

.company-details-container {
  .company-info-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 16px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 32px;

    .company-info-container {
      display: flex;
      flex-direction: column;
      gap: 5.5px;
      width: 300px;
      min-width: 300px;
      padding-right: 16px;
      box-sizing: border-box;
      margin-top: 24px;

      .company-form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0;
        justify-content: flex-start;
        min-height: 0;

        .form-inputs {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .form-group {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 16px;
        }
      }

      .kor-container {
        position: relative;
      }

      .kor-approved-at {
        margin: 12px 14px 0;
      }

      .info-label {
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #393a42;
        opacity: 0.6;
      }

      .info-value {
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #393a42;

        &.info-link {
          color: #1976d2;
          cursor: pointer;
        }
      }
    }
  }

  .overview-grids {
    .overview-grid-title {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 16px;
    }
  }
}
.assign-admin-wrapper {
  #assign-admin-button {
    font-weight: 700;
    font-size: 13px;
    line-height: 23px;
    color: #d32f2f;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
  }
}
