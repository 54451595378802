.proposal-status-chip {
  font-family: 'Sofia W05 Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #ffffff;
  height: 24px;

  &.pending {
    color: #393a42;
  }
}
