@import 'src/styles/globals';

.sign-up-container {
  display: flex;
  justify-content: center;
  gap: 120px;
  height: 100%;
}

.tofu-container {
  position: absolute;
  left: 50px;
}

.password-hint {
  padding: 0;
  margin: 0;
  font-family: 'Sofia W05 Light', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  .text {
    display: flex;
    gap: 7px;
    align-items: center;

    .MuiSvgIcon-root {
      width: 12px;
      height: 12px;
      color: #00bf36;
    }
  }
}

.agreement {
  font-family: 'Sofia W05 Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #515567;
  width: 360px;
  margin: 16px 0 0 0;

  a {
    color: #103bff;
  }
}

.sign-up-section {
  display: flex;
  flex-direction: column;
  width: 360px;

  @media screen and (max-width: $view-sm) {
    width: 100%;
  }

  .step-title,
  .step-subtitle {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #393a42;
    margin: 0;
    padding: 0;

    &.personal-info-subtitle {
      margin-bottom: 7.5px;

      .personal-student-avatar {
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }

  .step-title {
    margin: 0 0 32px 0;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.personal-form {
      gap: 32px;
    }
  }

  .form-inputs {
    display: flex;
    flex-direction: column;
    gap: 16.5px;
    margin-bottom: 40px;

    .info-tooltip {
      color: #1976d2;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .student-avatar {
      margin-bottom: 25px;
    }

    .array-buttons-container {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      .array-buttons {
        display: flex;
        box-sizing: border-box;
        padding: 4px 10px;
        width: auto;
        height: 31px;
        border-radius: 200px;
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 23px;
        letter-spacing: 0.46px;
        text-transform: uppercase;

        &.add-field-button {
          color: #2e7d32;
          border: 1px solid rgba(46, 125, 50, 0.5);
        }

        &.remove-field-button {
          border: 1px solid rgba(237, 108, 2, 0.5);
          color: #ed6c02;
        }
      }
    }

    .amount-control {
      .MuiFormHelperText-root {
        font-family: 'Sofia W05 Light', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .form-group {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: nowrap;

    #street-input,
    #city-input,
    #study-input {
      width: 240px;
    }

    #house-input,
    #postal-input,
    #degree-select {
      width: 104px;
    }

    #firstname-input,
    #lastname-input {
      width: 172px;
    }
  }
}

.mobile-form-progress {
  height: 2px;
  background: rgba(162, 170, 182, 0.3);
  margin-bottom: 12px;

  .MuiLinearProgress-bar {
    background: #deb900;
  }
}

.step-counter {
  font-family: 'Sofia W05 Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.001em;
  color: #4e5b73;
  opacity: 0.5;
  padding-bottom: 4px;
}

.form-buttons {
  display: flex;

  button {
    flex: 1;
    box-shadow: none;
    width: 172px;
    height: 42px;
    border-radius: 4px;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #393a42;

    &.confirm-button {
      background: #deb900;

      &.Mui-disabled {
        background: rgba(0, 0, 0, 0.12);
      }
    }

    &.back-button {
      background: #e0e0e0;
      box-shadow: none;
      margin-right: 24px;
    }
  }
}

.stepper-container-parent {
  @media screen and (max-width: $view-md) {
    width: 100%;
  }

  @media screen and (max-width: 1220px) {
    // display: none;
  }
}

.stepper-mobile-container {
  width: 100%;

  .stepper-progress-text {
    margin: 10px 0 0 0;
    font-family: 'Sofia W05 Medium', sans-serif;
    font-size: 1.1em;
    color: $grey;
  }
}

.stepper-container {
  height: 100%;
  display: flex;

  @media screen and (max-width: $view-md) {
    width: 100%;
  }

  .titles-container {
    display: grid;
    grid-gap: 60px;
    margin-right: 24px;

    .titles {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      color: #393a42;

      &.confirmed {
        color: #a2aab6;
      }

      .title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: right;

        width: 100%;
        justify-content: flex-end;
      }

      .description {
        position: absolute;
        top: 28px;
        font-family: 'Sofia W05 Light', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: right;
        justify-content: flex-end;
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  .stepper-wrapper {
    margin-top: 5px;
  }
}

.MuiStep-vertical {
  display: flex;
  justify-content: center;
}

.MuiStepLabel-root.MuiStepLabel-vertical {
  padding: 0;
  display: flex;
}

.MuiStepConnector-root.MuiStepConnector-vertical {
  margin-left: 8px;
  padding: 4px 0 8px 0;

  .MuiStepConnector-line.MuiStepConnector-lineVertical {
    display: block;
    min-height: 24px;
    width: 2px;
    background: #bdbdbd;
    height: 100%;
  }
}

.MuiStepLabel-iconContainer {
  padding: 0;

  &.Mui-completed {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #a2aab6;
    display: flex;
    align-items: center;
    justify-content: center;

    .CustomStepperIcon-completedIcon {
      color: #ffffff;
      width: 12px;
      height: 12px;
    }
  }

  .CustomStepperIcon-active {
    background: transparent;
    border: 2px solid #deb900;
    width: 18px;
    height: 18px;
    border-radius: 100%;
  }

  .CustomStepperIcon-circle {
    background: transparent;
    border: 2px solid #a2aab6;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-bottom: 3px;
  }
}

.select-role-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;

  @media screen and (max-width: $view-sm) {
    margin: 100px 0 180px 0;
  }

  .sides {
    display: flex;
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 0;

    @media screen and (max-width: $view-md) {
      &.community-wrapper {
        display: none;
      }
    }
  }

  .left-side {
    .title {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      margin: 0;
      padding: 0 0 16px 0;
      text-align: center;
      color: #393a42;
    }

    .subtitle {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.001em;
      color: #393a42;
      text-transform: none;

      .log-in-text {
        color: #1976d2;
        cursor: pointer;
      }
    }
  }

  .right-side {
    padding: 100px 48px 140px 48px;
    position: relative;
    background: #deb900;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0 0 48px 0;
    }

    @media screen and (max-width: $view-md) {
      display: none;
    }

    .title {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      width: 471px;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      color: #ffffff;
    }

    .description {
      width: 408px;
      height: 110px;
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    .avatars-container {
      display: flex;
      gap: 24px;
      margin: 0;

      .MuiAvatarGroup-avatar {
        border: 2px solid transparent;
        width: 40px;
        height: 40px;
      }

      .join-text {
        width: 321px;
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        margin: 0;
      }
    }

    .cat-background {
      img {
        position: absolute;
        right: 0;
        width: 690px;
        height: 470px;
        bottom: 0;
      }
    }
  }

  .sign-up-buttons-container {
    display: grid;
    grid-gap: 16px;
    width: 280px;
    margin-top: 32px;

    .button {
      background-color: $yellow;
      min-width: 200px;
      width: auto;
      height: 42px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      color: #393a42;
    }
  }
}

.company-form,
.student-form {
  display: flex;
  justify-content: center;
  padding: 60px 0 120px 0;
  gap: 122px;
  min-height: 300px;

  .step-description {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4e5b73;
    display: none;
    margin-bottom: 20px;

    .text-span {
      color: #deb900;
    }
  }
}

.browse-jobs-before-finish {
  left: 17vw;
  position: relative;
  width: fit-content;
}

@media (max-width: $view-xs) {
  .sign-up-container {
    display: none !important;
  }
}

@media (max-width: $view-sm) {
  .company-form,
  .student-form {
    gap: 0;
    flex-direction: column;
    padding: 0;

    .step-description {
      display: block;
    }
  }

  .tofu-container {
    display: none;
  }

  .stepper-container {
    margin-bottom: 5px;
  }

  .MuiBox-root.skeleton-wrapper {
    width: 100%;
  }
}

@media (max-width: $view-xs) {
  .welcome-section {
    .fee-container {
      width: 100%;
    }

    .calendly-inline-widget {
      width: 100%;
    }
  }
}

@media (max-width: 425px) {
  .company-form {
    width: 90vw;
  }
}
