.proposal-company-container {
  max-width: 483px;
  width: 100%;

  p {
    margin: 0;
  }

  .proposal-company-header {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 12px 24px;

    position: sticky;
    top: 0;

    background: linear-gradient(
        0deg,
        rgba(222, 185, 0, 0.3),
        rgba(222, 185, 0, 0.3)
      ),
      #ffffff;

    .close-icon-container {
      .close-drawer-icon {
        font-size: 21px;
        cursor: pointer;
      }
    }

    .title-container {
      padding-bottom: 21px;

      .title {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        color: #393a42;
      }
    }
  }

  .proposal-company-content {
    padding: 12px 0 0 0;
    margin: 0 16px;

    .student-header {
      border-bottom: 1px solid #e1e1e1;

      .student-titles {
        display: flex;
        justify-content: space-between;

        .student-study-container {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .student-study-titles {
            display: flex;
            gap: 8px;

            .student-avatar-image {
              width: 80px;
              height: auto;
            }

            .student-study {
              display: flex;
              flex-direction: column;
              gap: 12px;

              .study-title {
                font-family: 'Sofia W05 Medium', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;
                color: #393a42;
              }
            }
          }

          .rating {
            display: flex;
            height: fit-content;
            align-items: center;
            gap: 8px;

            .favourite-rate-star {
              color: #deb900;
            }

            .rate-number {
              font-family: 'Sofia W05 Regular', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #deb900;
            }
          }
        }
      }

      .student-one-liner {
        margin: 32px 0 16px 0;
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #393a42;
      }

      .student-languages {
        margin-bottom: 12px;
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #393a42;

        .value {
          font-family: 'Sofia W05 Regular', sans-serif;
          font-weight: 500;
        }
      }

      .student-skills {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
      }

      .job-header-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 28px;
        padding: 38px 0 18px 0;

        .button {
          padding: 0;
          font-family: 'Sofia W05 Semi Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: #deb900;
          cursor: pointer;
        }
      }
    }

    .proposal-info-container {
      margin-top: 16px;

      .title {
        margin-bottom: 24px;
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        color: #393a42;
      }

      .info-rows {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .info-row {
          display: flex;
          flex-wrap: nowrap;

          span,
          p {
            flex: 1 1 50%;
          }
        }

        .counter-offer-alert {
          .counteroffer-title {
            font-family: 'Sofia W05 Medium', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.001em;
          }

          .counteroffer-description {
            font-family: 'Sofia W05 Regular', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      .accordions-container {
        margin-top: 38px;
        display: flex;
        flex-direction: column;
        gap: 38px;
        .question-accordion {
          padding: 0;
        }

        .css-15v22id-MuiAccordionDetails-root {
          padding: 0;
        }

        .proposal-question {
          font-family: 'Sofia W05 Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.001em;
          color: #393a42;
        }

        .proposal-answer {
          font-family: 'Sofia W05 Regular', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #393a42;
          border-bottom: 1px solid #e1e1e1;
          padding: 0 0 16px 0;
        }
      }

      .proposal-action-container {
        margin-top: 48px;

        .title {
          font-family: 'Sofia W05 Semi Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 26px;
          color: #393a42;
          padding: 0;
          margin-bottom: 26px;
        }

        .action-list {
          .action-label {
            font-family: 'Sofia W05 Regular', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #393a42;
            padding-left: 15px;
          }
        }
      }
    }
  }

  .proposal-company-footer {
    margin-top: 60px;
    padding: 16px 24px;
    border-top: 1px solid #e1e1e1;

    .drawer-buttons {
      display: flex;
      justify-content: space-between;

      .proposal-actions {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        width: 100%;
      }
      .button {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        border-radius: 4px;

        &.chat,
        &.chat-button {
          background: #1976d2;
          min-width: 81px;
          height: 42px;
          color: #ffffff;
          width: fit-content;
        }

        &.reject {
          width: 92px;
          height: 42px;
          background: #d32f2f;
          color: #ffffff;
        }

        &.accept {
          min-width: 94px;
          height: 42px;
          background: #deb900;
          border-radius: 4px;
        }

        &.hire {
          width: fit-content;
          height: 42px;
          background: #14db87;
          border-radius: 4px;
        }

        &.max-reached {
          background: rgba(0, 0, 0, 0.12);
          color: rgba(0, 0, 0, 0.26);
          cursor: default;
        }
      }
    }
  }
}
