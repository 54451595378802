@import './src/styles/globals';

footer {
  .footer-container {
    width: 100%;
    height: 60px;
    background-color: $darkGrey;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 80px;

    @media screen and (max-width: $view-md) {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 20px 10px 20px;
      height: auto;
    }

    .logo-container {
      img {
        width: 158px;
        height: auto;
        position: relative;
        top: 6px;
      }

      @media screen and (max-width: $view-md) {
        margin: 0 0 15px 0;
      }
    }

    .routes-container {
      color: $lightGrey;
      display: flex;
      align-items: center;
      gap: 60px;

      @media screen and (max-width: $view-md) {
        gap: 20px;
      }

      ul {
        list-style: none;
        display: flex;
        color: $lightGrey;
        gap: 60px;
        padding: 0;
        font-size: 12px;

        @media screen and (max-width: $view-md) {
          gap: 20px;
          margin: 0;
        }
      }

      .routes-container-text {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-size: 1em;
        position: relative;
        top: 2px;
      }
    }
  }

  #lang-switcher {
    font-family: 'Sofia W05 Medium', sans-serif;
    background-color: $white;
    opacity: 1;
    color: $darkGrey;
    border-radius: 0;
  }
}

.newsletter-text {
  font-size: 14px;
  color: #393a42;
  font-family: 'Sofia W05 Light', sans-serif;
  width: 210px;
}
