.reminder-container-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 20px;
  border: 1px solid #c7d2e2;
  background-color: #ffffff;
  height: 78px;
  cursor: pointer;

  .lamp-image {
  }

  .reminder-card-content {
    width: max-content;

    .reminder-card-title {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.001em;
      color: #393a42;
      margin-bottom: 8px;
    }

    .reminder-card-text {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #393a42;
      max-width: 386px;
      line-height: 1em;
      height: 2.2em;
      white-space: pre-line;
    }
  }

  .reminder-card-link {
  }
}
