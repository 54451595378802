.favourite-card-container {
  height: 100%;
  background: #ffffff;
  margin: 0 10px;
  border: 1px solid #c7d2e2;
  font-family: 'Sofia W05 Regular', sans-serif;

  p {
    margin: 0;
  }

  .talent-avatar {
    width: 80px;
  }

  .card-header {
    .student-name-rate {
      display: flex;
      justify-content: space-between;
      padding: 24px;

      .name {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        color: #393a42;
      }

      .rate {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        padding-right: 8px;
        color: #deb900;
      }
    }
  }
  .card-content {
    .student-info {
      padding: 0 24px 16px 24px;
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #393a42;
    }
  }
  .card-footer,
  .talent-footer {
    padding: 16px 24px;
  }
  .card-footer {
    border-top: 1px solid #dadde2;

    .proposal-button {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .talent-footer {
    display: flex;
    justify-content: space-between;
  }
}
