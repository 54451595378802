.one-time-content-wrapper {
  ol.lst-kix_lo7wzc7ageg3-0.start {
    counter-reset: lst-ctn-kix_lo7wzc7ageg3-0 0;
  }

  .lst-kix_wh33pv27lh7k-3 > li {
    counter-increment: lst-ctn-kix_wh33pv27lh7k-3;
  }

  ol.lst-kix_wh33pv27lh7k-6.start {
    counter-reset: lst-ctn-kix_wh33pv27lh7k-6 0;
  }

  ol.lst-kix_lo7wzc7ageg3-7 {
    list-style-type: none;
  }

  ol.lst-kix_lo7wzc7ageg3-7.start {
    counter-reset: lst-ctn-kix_lo7wzc7ageg3-7 0;
  }

  ol.lst-kix_lo7wzc7ageg3-8 {
    list-style-type: none;
  }

  .lst-kix_wh33pv27lh7k-8 > li {
    counter-increment: lst-ctn-kix_wh33pv27lh7k-8;
  }

  ol.lst-kix_lo7wzc7ageg3-3.start {
    counter-reset: lst-ctn-kix_lo7wzc7ageg3-3 0;
  }

  .lst-kix_lo7wzc7ageg3-7 > li {
    counter-increment: lst-ctn-kix_lo7wzc7ageg3-7;
  }

  .lst-kix_wh33pv27lh7k-2 > li {
    counter-increment: lst-ctn-kix_wh33pv27lh7k-2;
  }

  ol.lst-kix_r7pvhwgbo37n-0 {
    list-style-type: none;
  }

  ol.lst-kix_r7pvhwgbo37n-0.start {
    counter-reset: lst-ctn-kix_r7pvhwgbo37n-0 0;
  }

  ol.lst-kix_wh33pv27lh7k-7.start {
    counter-reset: lst-ctn-kix_wh33pv27lh7k-7 0;
  }

  .lst-kix_lo7wzc7ageg3-1 > li {
    counter-increment: lst-ctn-kix_lo7wzc7ageg3-1;
  }

  .lst-kix_lo7wzc7ageg3-5 > li {
    counter-increment: lst-ctn-kix_lo7wzc7ageg3-5;
  }

  .lst-kix_wh33pv27lh7k-7 > li {
    counter-increment: lst-ctn-kix_wh33pv27lh7k-7;
  }

  ol.lst-kix_lo7wzc7ageg3-6.start {
    counter-reset: lst-ctn-kix_lo7wzc7ageg3-6 0;
  }

  .lst-kix_lo7wzc7ageg3-8 > li {
    counter-increment: lst-ctn-kix_lo7wzc7ageg3-8;
  }

  .lst-kix_r7pvhwgbo37n-7 > li:before {
    content: 'o  ';
  }

  .lst-kix_wh33pv27lh7k-4 > li {
    counter-increment: lst-ctn-kix_wh33pv27lh7k-4;
  }

  .lst-kix_r7pvhwgbo37n-8 > li:before {
    content: '\0025aa  ';
  }

  .lst-kix_lo7wzc7ageg3-2 > li {
    counter-increment: lst-ctn-kix_lo7wzc7ageg3-2;
  }

  .lst-kix_wh33pv27lh7k-1 > li {
    counter-increment: lst-ctn-kix_wh33pv27lh7k-1;
  }

  .lst-kix_wh33pv27lh7k-4 > li:before {
    content: '' counter(lst-ctn-kix_wh33pv27lh7k-4, lower-latin) '. ';
  }

  .lst-kix_wh33pv27lh7k-5 > li:before {
    content: '' counter(lst-ctn-kix_wh33pv27lh7k-5, lower-roman) '. ';
  }

  ol.lst-kix_wh33pv27lh7k-0.start {
    counter-reset: lst-ctn-kix_wh33pv27lh7k-0 0;
  }

  .lst-kix_wh33pv27lh7k-6 > li:before {
    content: '' counter(lst-ctn-kix_wh33pv27lh7k-6, decimal) '. ';
  }

  .lst-kix_wh33pv27lh7k-8 > li:before {
    content: '' counter(lst-ctn-kix_wh33pv27lh7k-8, lower-roman) '. ';
  }

  ol.lst-kix_wh33pv27lh7k-3.start {
    counter-reset: lst-ctn-kix_wh33pv27lh7k-3 0;
  }

  .lst-kix_wh33pv27lh7k-7 > li:before {
    content: '' counter(lst-ctn-kix_wh33pv27lh7k-7, lower-latin) '. ';
  }

  ol.lst-kix_wh33pv27lh7k-8.start {
    counter-reset: lst-ctn-kix_wh33pv27lh7k-8 0;
  }

  .lst-kix_wh33pv27lh7k-0 > li {
    counter-increment: lst-ctn-kix_wh33pv27lh7k-0;
  }

  .lst-kix_lo7wzc7ageg3-0 > li:before {
    content: 'Artikel ' counter(lst-ctn-kix_lo7wzc7ageg3-0, decimal) '. ';
  }

  ol.lst-kix_wh33pv27lh7k-2 {
    list-style-type: none;
  }

  ol.lst-kix_wh33pv27lh7k-1 {
    list-style-type: none;
  }

  .lst-kix_lo7wzc7ageg3-1 > li:before {
    content: '' counter(lst-ctn-kix_lo7wzc7ageg3-0, decimal) '.'
      counter(lst-ctn-kix_lo7wzc7ageg3-1, decimal) ' ';
  }

  ol.lst-kix_wh33pv27lh7k-0 {
    list-style-type: none;
  }

  ol.lst-kix_wh33pv27lh7k-6 {
    list-style-type: none;
  }

  .lst-kix_lo7wzc7ageg3-2 > li:before {
    content: '' counter(lst-ctn-kix_lo7wzc7ageg3-2, lower-latin) '. ';
  }

  ol.lst-kix_wh33pv27lh7k-5 {
    list-style-type: none;
  }

  ol.lst-kix_wh33pv27lh7k-4 {
    list-style-type: none;
  }

  ol.lst-kix_wh33pv27lh7k-3 {
    list-style-type: none;
  }

  .lst-kix_r7pvhwgbo37n-0 > li:before {
    content: '' counter(lst-ctn-kix_r7pvhwgbo37n-0, lower-latin) '. ';
  }

  .lst-kix_wh33pv27lh7k-6 > li {
    counter-increment: lst-ctn-kix_wh33pv27lh7k-6;
  }

  .lst-kix_lo7wzc7ageg3-3 > li:before {
    content: '(' counter(lst-ctn-kix_lo7wzc7ageg3-3, decimal) ') ';
  }

  ol.lst-kix_wh33pv27lh7k-8 {
    list-style-type: none;
  }

  ol.lst-kix_wh33pv27lh7k-1.start {
    counter-reset: lst-ctn-kix_wh33pv27lh7k-1 0;
  }

  ol.lst-kix_wh33pv27lh7k-7 {
    list-style-type: none;
  }

  .lst-kix_r7pvhwgbo37n-1 > li:before {
    content: 'o  ';
  }

  .lst-kix_wh33pv27lh7k-3 > li:before {
    content: '' counter(lst-ctn-kix_wh33pv27lh7k-3, decimal) '. ';
  }

  ul.lst-kix_r7pvhwgbo37n-2 {
    list-style-type: none;
  }

  ul.lst-kix_r7pvhwgbo37n-1 {
    list-style-type: none;
  }

  .lst-kix_wh33pv27lh7k-2 > li:before {
    content: '' counter(lst-ctn-kix_wh33pv27lh7k-2, lower-roman) '. ';
  }

  ul.lst-kix_r7pvhwgbo37n-4 {
    list-style-type: none;
  }

  .lst-kix_r7pvhwgbo37n-2 > li:before {
    content: '\0025aa  ';
  }

  ul.lst-kix_r7pvhwgbo37n-3 {
    list-style-type: none;
  }

  .lst-kix_r7pvhwgbo37n-4 > li:before {
    content: 'o  ';
  }

  ol.lst-kix_lo7wzc7ageg3-2.start {
    counter-reset: lst-ctn-kix_lo7wzc7ageg3-2 0;
  }

  .lst-kix_wh33pv27lh7k-1 > li:before {
    content: '' counter(lst-ctn-kix_wh33pv27lh7k-1, lower-latin) '. ';
  }

  ul.lst-kix_r7pvhwgbo37n-6 {
    list-style-type: none;
  }

  ol.lst-kix_lo7wzc7ageg3-8.start {
    counter-reset: lst-ctn-kix_lo7wzc7ageg3-8 0;
  }

  ol.lst-kix_wh33pv27lh7k-4.start {
    counter-reset: lst-ctn-kix_wh33pv27lh7k-4 0;
  }

  ul.lst-kix_r7pvhwgbo37n-5 {
    list-style-type: none;
  }

  ul.lst-kix_r7pvhwgbo37n-8 {
    list-style-type: none;
  }

  .lst-kix_lo7wzc7ageg3-4 > li {
    counter-increment: lst-ctn-kix_lo7wzc7ageg3-4;
  }

  .lst-kix_r7pvhwgbo37n-3 > li:before {
    content: '\0025cf  ';
  }

  ul.lst-kix_r7pvhwgbo37n-7 {
    list-style-type: none;
  }

  .lst-kix_wh33pv27lh7k-0 > li:before {
    content: '' counter(lst-ctn-kix_wh33pv27lh7k-0, decimal) '. ';
  }

  .lst-kix_r7pvhwgbo37n-6 > li:before {
    content: '\0025cf  ';
  }

  ol.lst-kix_lo7wzc7ageg3-5.start {
    counter-reset: lst-ctn-kix_lo7wzc7ageg3-5 0;
  }

  .lst-kix_wh33pv27lh7k-5 > li {
    counter-increment: lst-ctn-kix_wh33pv27lh7k-5;
  }

  .lst-kix_r7pvhwgbo37n-5 > li:before {
    content: '\0025aa  ';
  }

  ol.lst-kix_wh33pv27lh7k-2.start {
    counter-reset: lst-ctn-kix_wh33pv27lh7k-2 0;
  }

  .lst-kix_r7pvhwgbo37n-0 > li {
    counter-increment: lst-ctn-kix_r7pvhwgbo37n-0;
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  ol.lst-kix_lo7wzc7ageg3-5 {
    list-style-type: none;
  }

  ol.lst-kix_wh33pv27lh7k-5.start {
    counter-reset: lst-ctn-kix_wh33pv27lh7k-5 0;
  }

  .lst-kix_lo7wzc7ageg3-5 > li:before {
    content: '(' counter(lst-ctn-kix_lo7wzc7ageg3-5, lower-roman) ') ';
  }

  .lst-kix_lo7wzc7ageg3-6 > li:before {
    content: '' counter(lst-ctn-kix_lo7wzc7ageg3-6, decimal) '. ';
  }

  ol.lst-kix_lo7wzc7ageg3-6 {
    list-style-type: none;
  }

  ol.lst-kix_lo7wzc7ageg3-3 {
    list-style-type: none;
  }

  ol.lst-kix_lo7wzc7ageg3-4 {
    list-style-type: none;
  }

  ol.lst-kix_lo7wzc7ageg3-1 {
    list-style-type: none;
  }

  ol.lst-kix_lo7wzc7ageg3-2 {
    list-style-type: none;
  }

  .lst-kix_lo7wzc7ageg3-4 > li:before {
    content: '(' counter(lst-ctn-kix_lo7wzc7ageg3-4, lower-latin) ') ';
  }

  .lst-kix_lo7wzc7ageg3-8 > li:before {
    content: '' counter(lst-ctn-kix_lo7wzc7ageg3-8, lower-roman) '. ';
  }

  .lst-kix_lo7wzc7ageg3-0 > li {
    counter-increment: lst-ctn-kix_lo7wzc7ageg3-0;
  }

  ol.lst-kix_lo7wzc7ageg3-4.start {
    counter-reset: lst-ctn-kix_lo7wzc7ageg3-4 0;
  }

  ol.lst-kix_lo7wzc7ageg3-0 {
    list-style-type: none;
  }

  .lst-kix_lo7wzc7ageg3-3 > li {
    counter-increment: lst-ctn-kix_lo7wzc7ageg3-3;
  }

  ol.lst-kix_lo7wzc7ageg3-1.start {
    counter-reset: lst-ctn-kix_lo7wzc7ageg3-1 0;
  }

  .lst-kix_lo7wzc7ageg3-7 > li:before {
    content: '' counter(lst-ctn-kix_lo7wzc7ageg3-7, lower-latin) '. ';
  }

  .lst-kix_lo7wzc7ageg3-6 > li {
    counter-increment: lst-ctn-kix_lo7wzc7ageg3-6;
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c7 {
    margin-left: 18pt;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c4 {
    margin-left: 36pt;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c10 {
    margin-left: 54pt;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c2 {
    margin-left: 36pt;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c14 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Arial', sans-serif;
    font-style: normal;
  }

  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Calibri', sans-serif;
    font-style: normal;
  }

  .c6 {
    padding-top: 0;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c16 {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Calibri', sans-serif;
    font-style: normal;
  }

  .c11 {
    padding-top: 0;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c17 {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c13 {
    padding-top: 0;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: center;
  }

  .c8 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 28pt;
    font-style: normal;
  }

  .c9 {
    background-color: #ffffff;
  }

  .c3 {
    padding: 0;
    margin: 0;
  }

  .c1 {
    font-weight: 400;
    font-family: 'Calibri', sans-serif;
  }

  .c12 {
    font-weight: 700;
    font-family: 'Calibri', sans-serif;
  }

  .c15 {
    font-style: italic;
  }

  .title {
    padding-top: 0;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 0;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial', sans-serif;
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial', sans-serif;
  }

  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial', sans-serif;
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
