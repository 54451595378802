.form-group-input {
  width: 100%;
}

.form-input {
  width: 100%;
}

.email-verified-icon {
  color: #1976d2;
}

.verification-email-container {
  .verification-code-container {
    margin-top: 16px;
    margin-left: 16px;
  }
}

.verification-alert-bar {
  align-items: center;

  .MuiAlert-message {
    width: 100%;

    .verification-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .verification-text {
        margin: 0;
      }

      .email-verification-button {
        width: auto;
        white-space: nowrap;
        padding: 0 30px;
        height: 30px;
        background: #d32f2f;
        border-radius: 4px;
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        color: #ffffff;

        &.Mui-disabled,
        &[disabled] {
          color: rgba(0, 0, 0, 0.26);
          box-shadow: none;
          background-color: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}

.fill-manually-hint-container {
  font-family: 'Sofia W05 Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #393a42;

  .fill-manually-text {
    cursor: pointer;
    color: #1976d2;
  }
}

.business-link {
  position: absolute;
  left: 68px;
  top: 9px;
  text-decoration: underline;
}

.kor-checkbox-helper-text {
  font-family: 'Sofia W05 Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.verify-button-container {
  .verify-button {
    width: 65px;
    height: 30px;
    background: #deb900;
    border-radius: 4px;
    margin-bottom: 20px;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    color: #393a42;
  }
}

.account-financial-forms {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 24px;
  padding-top: 24px;

  &.account-container {
    border-top: none;
    margin-top: 0;
  }

  .account-financial-forms-titles {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #393a42;

    &.delete-account-title {
      color: #ea4335;
    }
  }

  .account-form,
  .financial-company-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .remove-button-container {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 16px;

      .remove-field-button {
        border: 1px solid rgba(211, 47, 47, 0.5);
        border-radius: 200px;
        color: #ea4335;
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 23px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
      }
    }

    .vat-number-container {
      margin-top: 49px;
      margin-bottom: 54px;
      width: 100%;

      .MuiTextField-root {
        width: 100%;
      }
    }

    .form-group {
      display: flex;
      flex-direction: row;
      gap: 16px;
      flex-wrap: nowrap;

      &.form-input-btn-group {
        align-items: flex-end;
      }

      .choose-logo-btn {
        width: auto;
        background: #deb900;
        white-space: nowrap;
        height: 36px;
        border-radius: 4px;
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #393a42;
      }
    }

    .company-logo-img {
      max-width: 80px;
      margin-bottom: 24px;
    }

    .form-array-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:not(:first-child) {
        margin-top: 24px;
        padding-top: 30px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
      }

      .remove-field-button {
        border: 1px solid rgba(211, 47, 47, 0.5);
        border-radius: 200px;
        color: #ea4335;
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 23px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
      }
    }

    .edit-profile-array-buttons-container {
      margin-top: 16px;
      display: flex;
      flex-direction: row-reverse;

      .add-field-button {
        border: 1px solid rgba(46, 125, 50, 0.5);
        border-radius: 200px;
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 23px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        color: #2e7d32;
      }
    }
  }

  .save-button-container {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    .save-button {
      width: auto;
      background: #deb900;
      border-radius: 4px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      color: #393a42;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  .delete-button-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;

    .delete-button {
      width: fit-content;
      background: #d32f2f;
      border-radius: 4px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .delete-account-caution {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.education-form {
  .save-button-container {
    margin-top: 24px;
  }

  &.without-border {
    border-top: none;
    padding-top: 0;
  }
}

.certificate-inputs {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.MuiInput-root {
  .remove-field-button {
    border: 1px solid rgba(211, 47, 47, 0.5);
    border-radius: 200px;
    color: #ea4335;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    position: absolute;
    right: 0;
    bottom: 7px;
  }
}

.company-level-form-tabs,
#company-level-tab-0,
#company-level-tab-1 {
  text-transform: none;
  height: 26px;
  font-family: 'Sofia W05 Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  &.Mui-selected {
    border-bottom-color: #deb900;
    color: #deb900;
  }
}
