.missing-info-modal {
  .content-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    padding: 16px 8px 16px 24px;
  }

  .content-text {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-size: 16px;
    color: #393a42;
    margin-bottom: 23px;
  }

  .button {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-size: 14px;

    &.agree {
      background-color: #deb900;
      width: auto;
    }
  }
}

.user-info-form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .MuiFormLabel-root.MuiInputLabel-root {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #393a42;
    opacity: 0.6;
  }

  .MuiInputBase-input.MuiInput-input {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #393a42;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .user-info-form-groups {
      flex: 0 1 calc(51.5% - 16px);
    }
  }
}
