.rating-modal {
  .button {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
  }

  .content-title {
    display: flex;
    justify-content: space-between;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
  }

  .boldText {
    font-weight: 700;
  }

  .content-text {
    font-family: 'Sofia W05 Regular', sans-serif;
    color: #393a42;
    margin-bottom: 25px;
  }

  .rating-title {
    margin-bottom: 5px;
  }

  .MuiDialogActions-root {
    width: fit-content;
    margin: 0 0 0 auto;
  }
}
