@import '../../styles/globals.scss';

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.timer {
  font-family: 'Sofia W05 Medium', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    color: #aaa;
  }

  .value {
    font-size: 32px;
  }
}

.assessment-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #dadde2;
  margin-bottom: 20px;
}

.results-description {
  text-align: center;
}

.already-tried-description {
  text-align: center;
}
