@import './src/styles/globals';

.open-job-container {
  padding: 10px;
  margin: 7px;
  border: 2px solid black;
  box-shadow: 5px 5px 0px 0px $yellow;

  .open-job-title {
    font-size: 130%;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .open-job-details {
    display: flex;
    gap: 20px;
    margin-bottom: 5px;
  }
}
