@import '../../styles/globals.scss';

.filter-swipeable-drawer {
  font-family: 'Sofia W05 Regular', sans-serif;

  .filter-sidebar {
    width: 304px;
    padding: 16px;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #393a42;
  }

  .filter-wrapper {
    margin-top: 22px;

    .label {
      font-family: 'Sofia W05 Medium', sans-serif;
    }

    .MuiTypography-root {
      font-family: 'Sofia W05 Regular', sans-serif;
    }
  }

  .min-max-block,
  .datepicker-wrapper {
    &.MuiFormGroup-root {
      flex-wrap: nowrap;
      align-items: flex-end;

      .MuiFormControl-root {
        width: 49%;
      }
    }
  }

  .datepicker-wrapper {
    .MuiInputBase-input {
      font-size: 14px;
    }
  }

  .separator {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #393a42;
    margin: 0 10px 10px;
  }

  .apply-button {
    margin: 40px 0 20px;
    font-weight: 700;
  }

  .clearFilters {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #1976d2;
  }
}
