@import '../../styles/globals.scss';

.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 100px auto;
  max-width: 1024px;

  .MuiTypography-h6 {
    font-family: 'Sofia W05 Bold', sans-serif;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
  }

  .team-img {
    max-width: 650px;
    border-radius: 15px;
    margin: 50px 15px 30px;
    width: 100%;
  }

  .photo-description {
    font-size: 20px;
    text-align: center;
    margin: 0 20px;
  }

  @media screen and (max-width: $view-sm) {
    .MuiTypography-h6 {
      font-size: 15px;
      text-align: center;
    }

    .team-img {
      width: 90%;
    }

    .photo-description {
      font-size: 15px;
    }
  }

  .link {
    color: #4486ff;
  }
}
