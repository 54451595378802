.assessment-card {
  border: 1px solid #dadde2;
  padding: 10px;

  .assessment-description {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .latest-assessment-score {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dadde2;
    border-top: 1px solid #dadde2;

    .label {
      color: rgba($color: #000000, $alpha: 0.7);
    }
  }
}

.assessmments-title-container {
  padding-bottom: 10px;
  border-bottom: 1px solid #dadde2;
  margin-bottom: 20px;
}
