@import 'src/styles/globals';

.promo-buttons {
  grid-gap: 12px;
  margin-bottom: 32px;

  &.center {
    justify-content: center;
  }

  @media screen and (max-width: $view-sm) {
    overflow-x: auto;
  }

  .promo-button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 12px 12px 12px;
    min-width: 286.5px;
    border: 2px solid #c7d2e2;

    .promo-button-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .promo-button-title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.001em;
        color: #393a42;
      }

      .promo-button-description {
        padding: 0;
        text-align: center;
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #393a42;
        list-style: none;

        li {
          font-family: 'Sofia W05 Light', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #393a42;
        }
      }
    }

    .promo-count {
      .promo-count-text {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1px;
        color: #deb900;
      }
    }

    .promo-button-icon-container {
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
