@import './src/styles/globals';

.notification-header {
  width: 100%;
  height: 35px;
  background-color: #deb900;
  z-index: 100;

  @media screen and (max-width: $view-md) {
    height: 55px;
  }

  @media screen and (max-width: $view-xs) {
    height: 65px;
  }
}

.notification-header-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  font-family: 'Sofia W05 Medium', sans-serif;

  @media screen and (max-width: $view-md) {
    padding: 13px 13px;
    text-align: center;
    font-size: 13px;
  }

  svg {
    margin-left: 10px;
  }
}
