@import '/src/styles/globals.scss';

.chat-container {
  width: 100%;

  .chat-avatar-wrapper {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }

  .chat-header-wrapper {
    display: flex;
  }

  .title-wrapper {
    width: 256px;
    margin-right: 30px;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      font-family: 'Sofia W05 Medium', sans-serif;
      margin-left: 15px;
      margin-top: 30px;

      @media all and (max-width: 880px) {
        margin: 0 0 15px 0;
      }
    }
  }

  @media all and (max-width: 880px) {
    margin-left: 0;

    .chat-header-wrapper {
      flex-direction: column;
    }
  }

  .MuiToolbar-root {
    margin-bottom: 20px;
  }

  .MuiAvatar-root {
    margin-right: 16px;
    width: 60px;
    height: 60px;
  }

  .user-name {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 4px;
    color: #393a42;
    font-family: 'Sofia W05 Semi Bold', sans-serif;

    &.line {
      margin: 0 5px;
    }
  }

  .messages-list {
    height: 80vh;
  }

  .chat-header {
    width: calc(100% - 305px);
    margin-left: 18px;
    display: flex;
    flex-direction: column;

    &.user {
      justify-content: end;
    }

    .chat-warning {
      font-size: 10px;
      font-family: 'Sofia W05 Regular', sans-serif;
      background: rgba(255, 255, 0, 0.125);
      text-align: center;
      padding: 5px;
    }
  }

  @media screen and (max-width: 880px) {
    .chat-header-wrapper {
      flex-direction: column;
    }

    .chat-header {
      width: 100%;
      margin-left: 0;
    }
  }
}

.suport-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 67px;
  padding: 12px 10px;

  &.active {
    background-color: #edf4fb;
  }

  .Avatar__avatar {
    overflow: hidden;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-style: solid;
    box-shadow: 0 1px 1px rgba(136, 153, 166, 0.15);
    border-radius: 50%;
    border-width: 2px;
    border-color: #fff;
    flex-shrink: 0;
    align-self: flex-start;
    width: 40px;
    height: 40px;
    margin-right: 15px;

    img {
      width: 40px;
      height: 40px;
      position: relative;
      left: -5px;
    }
  }

  span {
    color: #111;
    font-size: 13.5px;
    font-family: 'Sofia W05 Regular', sans-serif;
    font-weight: 700;
  }
}
