.log-in-text {
  color: #1976d2;
}

.toast-student {
  .description {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-size: 13px;
  }

  .toast-link {
    text-decoration: underline;
  }
}

.select-role {
  max-width: 360px;
  width: 100%;

  .user-details-form {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex-wrap: nowrap;
      width: 100%;

      .form-inputs {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
      }
    }
  }

  .user-role-wrapper {
    position: relative;
    display: flex;
    font-family: 'Sofia W05 Regular', sans-serif;
    flex: 1;
    width: 100%;

    button:first-child {
      margin-right: 20px;
    }

    button {
      font-family: 'Sofia W05 Semi Bold,sans-serif', sans-serif;
      flex: 1;
    }
  }

  .save-button {
    height: 46px;
    margin-top: 40px;
    font-family: 'Sofia W05 Regular', sans-serif;
  }

  .terms-and-conditions {
    display: flex;
    flex-wrap: wrap;

    label {
      margin-right: 7px;

      .MuiFormControlLabel-label {
        font-family: 'Sofia W05 Regular', sans-serif;
      }
    }

    .link {
      display: flex;
      align-items: center;
      font-weight: var(--amplify-font-weights-normal);
      color: #1976d2;
      width: fit-content;
      text-decoration: none;
    }
  }
}
