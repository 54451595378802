.admin-chat {
  .chat-page-tab {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    font-variant: small-caps;
    color: #393a42;
    opacity: 0.5;

    &.Mui-selected {
      border-bottom-color: #deb900;
      color: #deb900;
    }
  }

  .search-chat-input {
    width: 256px;
  }

  .chat-header {
    width: 100%;
    margin-left: 0;
  }

  .MuiTabs-indicator {
    height: 1px;
    background-color: #deb900;
  }

  .chat-wrapper {
    display: flex;
    margin-top: 25px;
    height: 83vh;

    .proposals-list {
      max-height: calc(90vh - 60px);
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid #edf4fb;
      height: fit-content;

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
    }

    .active {
      background: #edf4fb;
    }

    .user-chat-info {
      width: 256px;
      height: 60px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px;

      .avatar-list {
        position: relative;

        .job-details-company-logo-img {
          max-width: 40px;
          max-height: 40px;
        }

        .avatar-wrapper {
          border-radius: 50px;
          background-color: #e7ecee;
          border: 2px solid white;

          &.top {
            position: relative;
            top: -7px;
          }

          &.right {
            position: absolute;
            top: 5px;
            left: 20px;
            width: 40px;
          }
        }
      }

      .users-name {
        margin-left: 25px;
        font-family: 'Sofia W05 Medium', sans-serif;

        &.list {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
        }
      }
    }

    .messages-list {
      height: calc(90vh - 60px);
    }
  }

  .chat-box {
    width: calc(100% - 296px);
    margin-left: 40px;
    position: relative;
    top: -60px;

    .user-chat-info {
      width: 100%;
    }
  }

  .nothing-found {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Sofia W05 Medium', sans-serif;
  }

  .user-name {
    cursor: pointer;
  }

  .chat-avatar-wrapper {
    width: 80px;
    height: 80px;
    margin: 0 0 0 48px;
  }

  .chat-header-wrapper {
    display: flex;
  }

  .title-wrapper {
    width: 256px;
    margin-right: 30px;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      font-family: 'Sofia W05 Medium', sans-serif;
      margin-left: 15px;
      margin-top: 30px;

      @media all and (max-width: 880px) {
        margin: 0 0 15px 0;
      }
    }
  }

  .chat-header {
    align-items: center;
    flex-direction: row;
    min-height: 80px;
  }
}
