.minite-grid.MuiDataGrid-root {
  border: none;

  &.hide-pagination {
    .MuiDataGrid-footerContainer {
      display: none;
    }
  }

  .MuiDataGrid-columnHeaderTitle {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.001em;
    color: #393a42;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-cell {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #393a42;
  }

  .actions-bar {
    display: flex;
    align-items: center;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
  }
}
