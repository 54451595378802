.chat-float-button {
  position: fixed;
  z-index: 1000;
  bottom: 70px;
  right: 30px;
  background: #deb900;
  padding: 10px;
  border-radius: 50px;
  font-family: 'Sofia W05 Regular', sans-serif;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  color: black;

  .unread-messages {
    position: absolute;
    top: -7px;
    background: red;
    padding: 1px 5px;
    border-radius: 50px;
    min-width: 20px;
    font-size: 13px;
    text-align: center;
    right: 0;
    color: white;
  }
}
