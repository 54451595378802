.admin-grid-dashboard.admin-revenue-container {
  .section-sub-title {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
  }

  .table--wrapper {
    position: relative;

    .table-footer {
      position: absolute;
      bottom: 25px;
      display: flex;
      color: #393a42;
      font-style: normal;

      .total {
        width: 130px;
        padding: 0 10px;
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.001em;
      }

      .value {
        width: 140px;
        padding: 0 10px;
        font-family: 'Sofia W05 Regular', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .one-time-jobs {
    margin-top: 25px;
  }
}
