.delete-wrapper {
  width: fit-content;

  .delete-button {
    font-weight: 700;
    font-size: 13px;
    line-height: 23px;
    color: #d32f2f;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
  }
}
