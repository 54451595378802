.student-profile-page {
  p {
    padding: 0;
    margin: 0;
  }
}

.student-profile-breadcrumbs {
  font-family: 'Sofia W05 Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;

  .profile-link {
    cursor: pointer;
  }

  .profile-link-label {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #393a42;
    opacity: 0.6;
    text-decoration: none;

    &.last-link {
      color: #393a42;
      opacity: 1;
    }
  }
}

.profile-container {
  p {
    margin: 0;
  }

  .student-main-info {
    border-bottom: 1px solid #e1e1e1;
  }

  .one-liner-text {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #deb900;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e1e1e1;

    .one-liner-i-will {
      color: #d0ad00;
    }

    @media all and (max-width: 600px) {
      padding-top: 12px;
    }
  }

  .student-description {
    display: flex;
    justify-content: space-between;

    .student-description-text {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #393a42;
      margin-bottom: 24px;
      white-space: pre-line;
    }
  }

  .education-container,
  .work-experience-container,
  .certificates-container {
    .study-label,
    .secondary-labels {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      color: #393a42;
    }

    .study-label {
      font-size: 20px;
      line-height: 22px;
    }

    .secondary-labels {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.001em;

      .MuiListItemText-secondary {
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #a2aab6;
      }
    }

    .title {
      margin-bottom: 32px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      color: #393a42;

      &.cert-title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: #393a42;
        margin-bottom: 12px;
      }
    }

    .profile-info-list {
      padding: 0;
      gap: 24px;
      display: flex;
      flex-direction: column;

      .education-list-item,
      .experience-list-item {
        padding: 0;
        align-items: flex-start;

        .skills-row {
          display: flex;
          gap: 4px;
          margin-bottom: 16px;
          margin-top: 16px;
          flex-wrap: wrap;
        }

        .work-description {
          font-family: 'Sofia W05 Regular', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #393a42;
          word-break: break-word;
          white-space: pre-line;
        }
      }
    }

    .certificate-list {
      .certificate-list-item {
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #393a42;
        word-break: break-word;
      }
    }
  }

  .work-experience-container {
    .title {
      margin-top: 24px;
    }
  }

  .certificates-container {
    margin-top: 24px;
  }

  .student-sidebar-info {
    padding-right: 48px;

    .student-sidebar-header {
      padding-top: 72px;
      padding-bottom: 16px;
      margin-bottom: 36px;
      border-bottom: 1px solid #e1e1e1;

      .student-avatar-image {
        width: 80px;
      }

      @media all and (max-width: 600px) {
        padding-top: 24px;
      }

      .student-level {
        display: flex;
        justify-content: flex-end;
      }

      .student-name-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .student-name-alias {
        font-family: 'Sofia W05 Bold', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        color: #393a42;
        margin: 38px 0 20px 0;
      }

      .student-rate-rating-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .student-rate {
          font-family: 'Sofia W05 Semi Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 26px;
          color: #deb900;
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: baseline;

          .rate-per-hour-label {
            font-size: 14px;
            line-height: 16px;
          }
        }

        .student-rating-column {
          display: flex;
          flex-direction: column;

          .rating {
            display: flex;
            align-items: center;
            font-family: 'Sofia W05 Semi Bold', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 26px;
            color: #deb900;
          }

          .review-count {
            font-family: 'Sofia W05 Medium', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1px;
            color: #1976d2;
          }
        }
      }

      .student-stats {
        margin-top: 10px;
        font-family: 'Sofia W05 Medium', sans-serif;
      }
    }

    .student-language-container {
      margin-bottom: 16px;
      padding-bottom: 36px;
      border-bottom: 1px solid #e1e1e1;

      .student-language-list {
        padding: 0;

        .language-list-item {
          padding: 0;

          .language-item-avatar {
            width: 40px;
            min-width: 40px;
          }

          .student-language-item {
            margin-bottom: 24px;
            white-space: nowrap;

            .language-label {
              font-family: 'Sofia W05 Regular', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #393a42;
            }

            .language-secondary-label {
              font-family: 'Sofia W05 Regular', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #393a42;
              opacity: 0.5;
            }
          }
        }
      }
    }

    .student-skills-container,
    .student-interests-container,
    .personal-sentences-container {
      .title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: #393a42;
        margin-bottom: 12px;
      }

      .skill-interest-list {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
      }
    }

    .student-skills-container {
      margin-bottom: 32px;
    }

    .student-interests-container {
      padding-bottom: 16px;
    }

    .personal-sentences-container {
      margin-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #e1e1e1;

      .personal-sentences-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .personal-sentence-entry {
        display: flex;
        flex-direction: column;
        overflow-wrap: break-word;

        .question {
          font-weight: bold;
        }
      }
    }

    .add-talent-pool-btn {
      background: #deb900;
      border-radius: 4px;
      width: 100%;
      height: 36px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: #393a42;
      margin-top: 16px;
    }
  }

  .profile-actions-container {
    padding-left: 16px;
    border-left: 1px solid #e1e1e1;

    .profile-actions-title {
      padding-top: 30px;
      margin-bottom: 36px;
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: #000000;
    }

    .profile-actions-wrapper {
      .profile-actions-list {
        padding: 0;

        .profile-actions-list-item {
          padding: 0;

          .profile-action {
            display: flex;
            align-items: center;
            gap: 15px;

            font-family: 'Sofia W05 Regular', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #393a42;
            cursor: pointer;

            .profile-action-label {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.profile-actions-drawer {
  padding: 0 15px;

  .profile-actions-container {
    .profile-actions-title {
      margin-bottom: 36px;
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: #000000;
    }

    .profile-actions-wrapper {
      .profile-actions-list {
        padding: 0;

        .profile-actions-list-item {
          padding: 0;

          .profile-action {
            display: flex;
            align-items: center;
            gap: 15px;

            font-family: 'Sofia W05 Regular', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #393a42;
            cursor: pointer;

            .profile-action-label {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .profile-container.MuiGrid-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 16px;
  }
}
