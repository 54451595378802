.verify-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .verify-title {
    display: flex;
    flex-direction: column;
    width: 360px;
    gap: 16px;
    margin: 0;

    .title {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #393a42;
      margin: 0;
    }

    .sub-title {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #393a42;
      justify-content: center;
      margin: 0;
    }
  }

  .verify-input {
    width: 200px;
  }

  .resend-code-container {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: baseline;
    text-align: center;
    letter-spacing: 0.1px;
    color: #393a42;

    .resend-code-button {
      text-transform: none;
      text-decoration: none;

      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: baseline;
      text-align: center;
      letter-spacing: 0.1px;
      color: #1976d2;

      &:disabled {
        color: initial;
      }
    }
  }

  .verify-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 200px;
    height: 42px;
    background: #deb900;
    border-radius: 4px;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #393a42;
  }
}
