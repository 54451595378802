.hire-student-form {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .input-wrapper {
    position: relative;

    .MuiFormControl-root {
      width: 100%;
    }

    .input-block {
      position: absolute;
      top: 15px;
      height: 32px;
      margin: 0 10px;
      width: calc(100% - 40px);
      cursor: pointer;
    }
  }
}
