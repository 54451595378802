.edit-profile-page {
  box-sizing: border-box;

  .edit-profile-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #393a42;
  }

  .toast-student {
    .description {
      font-family: 'Sofia W05 Regular', sans-serif;
    }

    .toast-link {
      text-decoration: underline;
    }
  }
}

.calendly-container-profile-student {
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
