.favourites-section {
  align-items: stretch;

  p {
    margin: 0;
  }

  .react-multi-carousel-dot-list {
    position: relative !important;
  }
}
