.student-drawer-container {
  padding: 21px 0 0 0;
  max-width: 483px;
  width: 100%;

  p {
    margin: 0;
  }

  .student-drawer-header {
    padding: 0 24px;
    display: flex;
    width: 100%;
    .title-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        color: #393a42;
      }

      .close-drawer-icon {
        cursor: pointer;
      }
    }
  }

  .student-drawer-content {
    padding: 0 24px;
    margin-top: 23px;

    .status-row {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 23px;

      .status-title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: #393a42;
      }

      .proposal-status {
      }
    }
    .form-container {
      .rate-title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: #393a42;
        margin: 0 0 16px 0;
      }

      .student-proposal-form {
        .proposal-container {
          height: 48px;
          margin-top: 16px;
          margin-bottom: 10px;
          display: flex;
          align-items: flex-end;

          .toast-student {
            .description {
              font-family: 'Sofia W05 Regular', sans-serif;
              font-size: 13px;
            }

            .toast-link {
              text-decoration: underline;
            }
          }

          .proposal-title {
            font-family: 'Sofia W05 Semi Bold', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 26px;
            color: #393a42;
          }
        }

        .MuiFormControlLabel-root {
          margin-top: 40px;
          align-items: flex-start;

          .MuiButtonBase-root.MuiCheckbox-root {
            padding: 0 12px 0 0;
          }

          .MuiFormControlLabel-label {
            font-family: 'Sofia W05 Regular', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #393a42;
          }
        }

        .min-month-control {
          margin-top: 32px;
        }
      }
    }
  }

  .student-drawer-footer {
    height: 68px;
    margin-top: 17px;
    padding: 16px 24px;
    border-top: 1px solid #e1e1e1;

    .drawer-buttons {
      display: flex;
      justify-content: flex-end;

      .button {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-size: 14px;

        &.submit-proposal {
          background-color: #deb900;
          width: auto;
          margin-left: 18px;
        }

        &.retract {
          background: #d32f2f;
          color: #ffffff;
        }
      }
    }
  }
}

.MuiFormLabel-root.MuiInputLabel-root {
  &#how_you_do_x-label,
  &#how_you_do_y-label {
    white-space: pre-line;
    height: auto;
    width: max-content;
    position: relative;
    padding-bottom: 10px;
  }
}

.question--wrapper .MuiInputBase-root {
  margin-top: auto;
}
