@import 'src/styles/globals';

.onboarding,
.onboarding-swipeable-drawer .MuiPaper-root {
  font-family: 'Sofia W05 Regular', sans-serif;

  .stepper-container {
    flex-direction: row-reverse;
    margin-top: 32px;
    justify-content: start;

    .titles {
      .title,
      .description {
        text-align: left;
        justify-content: left;
      }
    }
  }

  .description {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin: 13px 0 24px;

    &.isBold {
      margin: 5px 0 5px;
    }

    .bold-text {
      font-family: 'Sofia W05 Bold', sans-serif;
      margin-right: 5px;
    }
  }

  .info-link {
    color: #4486ff;
    text-decoration: underline;
  }

  .list-item {
    margin: 5px 0;
  }

  .sidepanel {
    border-left: 1px solid #e0e0e0;
    border-right: none;
    padding-left: 16px;
    max-width: 256px;
    overflow: unset;
    padding-bottom: 50px;

    .sidepanel-top {
      padding: 0 0 34px;
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 32px;
    }

    .sidepanel-title {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: #393a42;
    }

    .sidepanel-description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #393a42;
      margin: 12px 0 34px;
    }

    .sidepanel-icon {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #393a42;

      .MuiSvgIcon-root {
        fill: #4e5b73;
        width: 20px;
        margin-right: 10px;
      }

      .verified-user-icon {
        fill: #1976d2;
      }

      &.completed {
        margin-top: 12px;
        color: #1976d2;
      }
    }
  }

  .onboarding-container-parent {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .onboarding-content-title {
      font-weight: 400;
      font-size: 48px;
      line-height: 52px;
      color: #393a42;
      margin-bottom: 24px;

      @media all and (max-width: $view-sm) {
        font-weight: 800;
        font-size: 32px;
        line-height: 44px;
      }
    }

    .onboarding-content-info {
      max-width: 512px;

      .title {
        font-size: 17px;
        font-family: 'Sofia W05 Bold', sans-serif;
        margin: 24px 0 5px;
      }

      @media all and (max-width: $view-sm) {
        width: 100%;
      }

      .onboarding-content-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;

        .MuiButton-root {
          font-family: 'Sofia W05 Medium', sans-serif;
          width: auto;
          color: $black;
        }

        .MuiButton-contained {
          background-color: $yellow;
          color: $black;
          font-family: 'Sofia W05 Semi Bold', sans-serif;

          &.pay-button {
            background: #14db87;
            border-radius: 4px;
          }

          &.Mui-disabled {
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
          }
        }

        .MuiButton-text:disabled {
          opacity: 0.26;
        }

        .MuiButton-text:disabled {
          opacity: 0.26;
        }

        .MuiButton-text:disabled {
          opacity: 0.26;
        }
      }
    }
  }

  .onboarding-content-buttons-mobile {
    display: flex;
    margin-left: 12px;

    .MuiButtonBase-root {
      width: 24px;
      min-width: 24px;

      .MuiSvgIcon-root {
        font-size: 14.5px;
        fill: #4e5b73;
      }
    }
  }

  @media all and (max-width: $view-sm) {
    .stepper-container {
      margin: 0;
    }
  }

  .MuiMobileStepper-root {
    width: 100%;
    margin-left: 12px;

    .MuiMobileStepper-progress {
      width: 100%;
      background-color: rgba(162, 170, 182, 0.3);
      overflow: hidden;
    }

    .MuiLinearProgress-bar {
      background-color: $yellow;
    }
  }

  .stepper-onbording-wrapper {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 25px;

    .menu-container {
      fill: #4e5b73;
      margin-right: 15px;
      display: flex;
    }
  }

  .stepper-mobile-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .stepper-container {
      justify-content: flex-end;
      margin: 0;
    }

    .stepper-progress-text {
      margin: 0;
    }
  }

  .stepper-progress-text {
    color: #393a42;
    opacity: 0.5;
  }
}

.onboarding-swipeable-drawer .MuiPaper-root {
  width: 90%;
  padding: 21px 24px;

  .sidepanel {
    border: none;
    margin: auto;
    max-width: 100%;
    padding: 0;

    .stepper-mobile-container {
      display: none;
    }

    .sidepanel-top {
      .sidepanel-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        color: #393a42;
      }
    }
  }
}

.onboarding-modal {
  .onboarding-content-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #393a42;
    font-family: 'Sofia W05 Regular', sans-serif;
  }

  .MuiDialogTitle-root.content-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #393a42;
  }
}
