.admin-timesheet-details-container {
  .timesheet-link {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #1976d2;
    text-decoration: none;
    cursor: pointer;
  }

  .label-detail {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #393a42;
    opacity: 0.6;
    margin-bottom: 6px;
  }

  .MuiChip-root {
    height: 24px;
  }

  .timesheet-grid-container {
    width: 100%;
    margin-bottom: 25px;

    .month-tabs {
      margin-top: 12px;
    }

    .invoice-buttons {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 10px;
    }

    .loading-invoices {
      height: 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .total-hours-month {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.001em;
    color: #000000;
    font-family: 'Sofia W05 Medium', sans-serif;
  }

  .hours-container {
    margin-bottom: 44px;

    .hour-item {
      margin-bottom: 16px;
    }
  }
}
