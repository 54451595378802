@import '../../../../styles/globals.scss';

.job-details-company-logo-img {
  max-width: 80px;
  max-height: 74px;
  margin-right: 32px;
}

.job-chip-wrapper {
  margin-bottom: 10px;
}

.confirm-button.apply-button.confirm-mobile-button {
  margin: 16px 0;
}

.job-details-student-view-container {
  width: 100%;

  h3 {
    margin: 8px 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .rate {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    margin-right: 10px;

    .favourite-rate-star {
      width: 20px;
      height: 20px;
      color: #deb900;
    }

    .rate-number {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #deb900;
    }
  }

  .placeholder {
    width: 80px;
    height: 80px;
    background-color: aquamarine;
    margin-right: 32px;
    border-radius: 10px;
  }

  .heading-text {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    text-transform: capitalize;
  }

  .regular-text {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .medium-text {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-weight: 500;
    word-break: break-word;
  }

  .job-container {
    display: flex;
    flex-direction: column;
  }

  .job-details {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $view-sm) {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 20px;
    }
  }

  .company-details {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;

    @media screen and (max-width: $view-sm) {
      padding: 20px 15px;
    }

    .apply-button {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
    }

    .details-container {
      display: flex;
      width: 100%;

      .title {
        font-size: 16px;
        color: #4e5b73;
      }

      .value {
        font-size: 16px;
        color: #393a42;
      }

      .company-label {
        font-size: 14px;
      }
    }

    .info {
      width: 100%;
      display: flex;
      flex-direction: column;

      &.company-info {
        margin-top: 32px;
        margin-bottom: 24px;
      }

      &.company-address,
      &.company-description {
        margin-bottom: 24px;
      }

      p {
        display: flex;
        flex-wrap: nowrap;
        gap: 8px;

        a {
          color: #1976d2;
          cursor: pointer;
        }
      }

      .info-title {
        font-size: 16px;
        color: #4e5b73;
      }

      .info-value {
        font-size: 16px;
        color: #393a42;
      }
    }

    .company-description {
      font-size: 14px;
      color: #4e5b73;
    }
  }

  .title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
  }

  .review-count {
    color: #1976d2;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Sofia W05 Semi Bold', sans-serif;
  }

  .rating {
    color: #deb900;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Sofia W05 Regular', sans-serif;
  }

  .brief-info {
    border-bottom: 1px solid #e1e1e1;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 15px;
    }

    .left {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media screen and (max-width: $view-sm) {
        flex-direction: column;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .info-title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.001em;
        color: #4e5b73;

        .info-value {
          color: #393a42;
        }
      }

      .date {
        color: #4e5b73;
      }

      .top-chip {
        margin-bottom: 5px;
      }
    }

    .titles-container {
      .company-detail {
        display: flex;
        align-items: center;
      }

      .title {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        color: #393a42;
      }

      .company-label {
        font-family: 'Sofia W05 Medium', sans-serif;
        color: #393a42;
        margin: 0;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;

      .actions {
        display: flex;

        .button:not(.Mui-disabled) {
          color: #deb900;
        }

        @media all and (max-width: 600px) {
          margin-bottom: 24px;
          margin-left: 12px;
        }
      }

      .applied {
        color: #4e5b73;
        text-align: center;
      }
    }
  }

  .confirm-button-mobile-container {
    border-bottom: 1px solid #e1e1e1;
  }

  .location {
    text-transform: capitalize;
  }

  .main {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 24px;
      }

      .budget {
        border-bottom: 3px solid #deb900;
        font-size: 36px;
        text-transform: lowercase;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: $view-sm) {
        flex-direction: column-reverse;
        justify-content: flex-start;

        .right {
          margin-bottom: 8px;
        }

        .left,
        .right {
          gap: 8px;
        }
      }

      .info-title {
        font-size: 16px;
        color: #4e5b73;
        font-family: 'Sofia W05 Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.001em;
      }

      .info-value {
        font-size: 16px;
        color: #393a42;
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media screen and (max-width: $view-sm) {
          align-items: flex-start;
        }
      }

      .skills-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .MuiChip-root {
          background-color: #f2f9fc;
          color: #393a42;
        }
      }
    }

    .description {
      .title {
        color: #393a42;
        font-size: 20px;
      }

      .text {
        font-size: 14px;
        color: #4e5b73;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        max-width: 600px;
        font-family: 'Sofia W05 Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}

.job-drawer-student--wrapper {
  .MuiPaper-elevation {
    width: 100%;
    max-width: 483px;
  }
}
