@import './src/styles/globals';

.header {
  width: 100%;
  height: 62px;
  background-color: #f9f9f9;
  z-index: 100;

  @media screen and (max-width: $view-xs) {
    height: 51px;
  }
}

.header-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 24px;

  @media screen and (max-width: $view-md) {
    padding: 13px 13px;
  }

  .header-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .menu-container {
      margin-right: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;

      @media screen and (min-width: 1199px) {
        display: none;
      }

      @media screen and (max-width: $view-md) {
        margin-right: 13px;
      }
    }
  }

  .routes-container {
    color: $grey;
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 15px;

    ul {
      list-style: none;
      display: flex;
      gap: 12px;
    }

    @media screen and (max-width: 320px) {
      gap: 0;
      margin-left: 3px;
    }
  }
}

.logo-container {
  img {
    height: 46px;

    @media screen and (max-width: $view-md) {
      height: 30px;
    }
  }
}

.sign-up {
  width: 89px;
  height: 36px;
  background-color: $yellow;
  border-radius: 4px;
}

.log-in {
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.header-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .header-route-button {
    width: auto;
    padding: 16px;
    margin-right: 20px;

    @media screen and (min-width: $view-sm) {
      &.browse-job {
        width: 325px;
      }
    }

    @media screen and (max-width: $view-sm) {
      &.browse-job {
        width: 150px;
      }
    }

    @media screen and (max-width: $view-md) {
      margin-right: 8px;
      padding: 4px 10px;
      font-size: 13px;
    }

    @media screen and (max-width: 320px) {
      margin-right: 0;
      font-size: 11.5px;
    }
  }
}

.header-icon-button-container {
  .MuiSvgIcon-root {
    color: $grey;
    margin: 5px;

    @media screen and (max-width: $view-md) {
      margin: 0;
    }
  }

  .logout-button {
    @media screen and (max-width: 350px) {
      min-width: 45px;
      font-size: 12px;
      padding-right: 0;
    }
  }
  img {
    width: 30%;
    cursor: pointer;
  }
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  opacity: 1;
}
