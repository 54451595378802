.mc-container {
  width: 100%;
}
.mc-options {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mc-submit-button-container {
  border-top: 1px solid #dadde2;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
