.admin-reviews-container {
  .grid-title-row {
    .section-sub-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
    }
  }
}
