.resizable-table-wrapper {
  .MuiPaper-root.MuiPaper-elevation {
    box-shadow: none;
  }

  .MuiTableCell-root.MuiTableCell-body {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #393a42;
  }

  .Mui-TableHeadCell-Content-Labels .Mui-TableHeadCell-Content-Wrapper {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.001em;
    color: #393a42;
  }
}
