@import '../../styles/globals.scss';

.reset-form-container,
.success-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  margin: 60px auto 120px auto;

  @media screen and (max-width: $view-md) {
    margin: 60px 0 120px 0;
  }

  @media screen and (max-width: $view-xs) {
    width: 95%;
  }

  .greeting {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 56px;
    width: 100%;

    @media screen and (max-width: $view-xs) {
      align-items: flex-start;
    }

    p {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #393a42;
      margin: 0;
    }

    span {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #393a42;
      width: 550px;

      @media screen and (max-width: $view-xs) {
        text-align: left;
        align-items: flex-start;
      }

      @media screen and (max-width: $view-md) {
        width: 100%;
      }
    }
  }
}

.reset-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Sofia W05 Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #393a42;

  .form-inputs {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 40px;
  }
}

#reset-button,
#change-password-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  max-width: 360px;
  height: 46px;
  background-color: #deb900;
  color: #393a42;
  border-radius: 4px;
}

#change-password-button {
  margin-top: 40px;

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
}

#reset-button {
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.back-to-login {
  width: 100%;
  margin-top: 24px;
  font-family: 'Sofia W05 Light', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #393a42;

  span {
    padding-right: 4px;
  }

  .login-text {
    color: #1976d2;
    cursor: pointer;
  }
}

.user-not-exist {
  margin-top: 10px;

  .signup-text {
    color: #1976d2;
    cursor: pointer;
    margin-right: 5px;
  }
}

.success-form-container {
  .greeting {
    margin-bottom: 40px;

    span {
      display: inline;
    }
  }

  .back-to-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 360px;
    height: 42px;
    text-transform: capitalize;
    background: #deb900;
    border-radius: 4px;

    span {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      color: #393a42;
    }
  }
}
