.dashboard-job-card-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 20px 20px 16px;
  width: 316px;
  height: auto;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin: 16px;

  &:first-of-type {
    margin-left: 0 !important;
  }

  .dashboard-job-card-avatar {
    img {
      width: 77px;
    }
  }

  .dashboard-job-card-text {
    width: 100%;

    .job-title {
      margin-top: 14px;
      margin-bottom: 12px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      color: #393a42;
    }

    .dashboard-job-card-content {
      display: flex;
      justify-content: space-between;

      .card-rating-name-container {
        display: flex;
        flex-direction: row;

        .rating {
          display: flex;
          grid-gap: 6px;
          margin-right: 16px;
          align-items: center;
          font-family: 'Sofia W05 Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.001em;
          color: #deb900;
        }

        .company-name {
        }
      }
    }
  }
}
