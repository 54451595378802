.assessment-question {
  border: 1px solid #dadde2;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 100%;

  @media (max-width: 900px) {
    margin-left: 0px;
  }
}
