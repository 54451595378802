.timesheet-container {
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
    > .MuiDataGrid-cell.minite-editable-cell {
    white-space: normal;
  }

  .company-details {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .job-titles {
      display: flex;
      align-items: center;

      .company-avatar {
        width: 80px;
        height: 80px;
        margin-right: 24px;
      }

      .job-details {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .job-title {
          font-family: 'Sofia W05 Semi Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 26px;
          color: #393a42;
        }

        .job-company-name,
        .job-rate {
          font-family: 'Sofia W05 Regular', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #393a42;
        }
      }
    }
  }

  .cards-wrapper {
    display: flex;

    .total-current-month-hours {
      height: 150px;
      margin-left: 10px;
    }
  }

  .skeleton-wrapper {
    margin-top: 15px;
    height: 250px;
  }

  .input-wrapper {
    position: relative;

    .input-block {
      position: absolute;
      top: 0;
      height: 32px;
      margin: 0 10px;
      width: calc(100% - 40px);
      cursor: pointer;
    }
  }

  .years-container {
    margin-bottom: 10px;

    .info-label {
      margin-right: 10px;
    }
  }

  .timesheet-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    .total-hours-month {
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.001em;
      color: #000000;
      margin-right: 12px;
    }

    .submit-month-button {
      min-width: 150px;
      width: auto;
    }

    .approve-month-button {
      min-width: 165px;
      width: auto;
    }

    .reject-month-button {
      min-width: 155px;
      width: auto;
      margin-right: 12px;
    }
  }

  .progress-bar {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #393a42;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .timesheet-totals {
    font-family: 'Sofia W05 Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    .total-hours-month {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #393a42;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
}

.reject-reason-form {
  margin-top: 42px;
}

.add-entry-button.MuiButton-text {
  font-family: 'Sofia W05 Semi Bold', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: #deb900;
}

.minite-editable-cell {
  min-height: 52px;
  .edit-cell-field {
    width: 100%;
  }

  .MuiDataGrid-cellContent {
    word-break: break-word;
    padding: 5px 0;
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    padding: 0 10px;
  }

  .Mui-error {
    height: 100%;
    border-radius: 0;
  }
}

.add-entry-button.MuiButton-text {
  font-family: 'Sofia W05 Semi Bold', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: #deb900;
}

.minite-editable-cell {
  .edit-cell-field {
    width: 100%;
  }

  .Mui-error {
    height: 100%;
    border-bottom: 1px solid #ff0000ff;
  }
}

.timesheet-grid-container {
  .month-tabs {
    min-height: 40px;
    height: 40px;

    .month-tab {
      text-transform: capitalize;
      font-family: 'Sofia W05 Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;

      &.selected,
      &.Mui-selected {
        color: #deb900;
      }
    }

    .MuiTabs-indicator {
      height: 1px;
      background-color: #deb900;
    }
  }
}

@media all and (max-width: 600px) {
  .timesheet-container {
    .company-details {
      flex-direction: column;

      .job-titles {
        align-items: flex-start;
        margin-top: 24px;

        .company-avatar {
          width: 32px;
          height: 32px;
        }
      }

      .total-hours {
        margin-top: 32px;
        margin-bottom: 24px;
        width: 140px;
        height: 96px;
        min-width: 140px;
        padding: 8px;
        justify-content: flex-end;

        .statistic-card-number {
          font-size: 24px;
          line-height: 26px;
        }

        .statistic-card-text {
          font-family: 'Sofia W05 Regular', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #393a42;
        }
      }
    }
  }
}
