.agreement-view-container {
  .section-title {
    font-size: 24px;
  }

  .section-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #46566e;
    font-family: 'Sofia W05 Regular', sans-serif;
    margin-top: 24px;
    display: inline-block;
  }

  .agreement-info {
    margin: 24px 0 40px;

    .doc-content {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 530px;
      height: 90vh;
      border: 1px solid #e1e1e1;
      padding: 15px 15px 0;
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;

    .button {
      font-family: 'Sofia W05 Semi Bold', sans-serif;
    }

    .confirm {
      width: fit-content;
    }
  }

  .footer-wrapper {
    display: flex;
    justify-content: space-between;

    .footer-text {
      color: #000000;
      margin: 16px;
      font-weight: 200;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 10px;
      font-family: 'Calibri', sans-serif;
      font-style: normal;
      width: 49%;
    }
  }
}
