.reviews-table {
  .MuiTable-root {
    font-family: 'Sofia W05 Regular', sans-serif;

    .MuiTableRow-head {
      border-bottom: 1px solid rgb(224, 224, 224);

      .MuiTableCell-head {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.87);
        font-family: 'Sofia W05 Medium', sans-serif;
      }
      .MuiTableCell-root {
        padding: 0 8px 6px;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          font-family: 'Sofia W05 Regular', sans-serif;
          border-bottom: none;
          padding: 12px 8px;
          color: rgba(0, 0, 0, 0.87);
        }

        &:hover {
          background: #f0f0f0;
        }

        .MuiBox-root {
          margin: 0 0 16px;
        }
      }
    }

    .MuiButtonBase-root {
      padding: 0;
    }
  }

  .MuiToolbar-root {
    .MuiSvgIcon-root {
      fill: #4e5b73;
    }
  }
}
