@import 'src/styles/globals';

.branding {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
}

[data-amplify-authenticator] {
  --amplify-colors-background-primary: #deb900;
  --amplify-colors-background-secondary: #deb900;
  --amplify-colors-brand-primary-10: #deb900;
  --amplify-colors-brand-primary-80: #deb900;
  --amplify-colors-brand-primary-90: #deb900;
  --amplify-colors-brand-primary-100: #deb900;
  --amplify-colors-font-interactive: #deb900;
  --amplify-components-tabs-item-active-color: #deb900;
  --amplify-components-tabs-item-focus-color: #deb900;
  --amplify-components-tabs-item-hover-color: #deb900;
  --amplify-components-tabs-item-active-border-color: #deb900;
  --amplify-components-authenticator-router-border-color: transparent;
  --amplify-components-button-active-background-color: #deb900;
  --amplify-components-button-primary-color: #000;
  --amplify-components-button-hover-color: #deb900;
  --amplify-components-button-primary-background-color: #deb900;
  --amplify-components-button-primary-hover-background-color: #deb900;
  --amplify-components-button-primary-focus-background-color: #deb900;
  --amplify-components-button-primary-active-background-color: #deb900;
  --amplify-components-button-link-hover-color: #1976d2;
  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-button-link-color: #1976d2;
  --amplify-components-button-hover-background-color: transparent;
  --amplify-components-button-hover-border-color: #deb900;
  --amplify-components-passwordfield-button-hover-border-color: var(
    --amplify-components-button-border-color
  );
  --amplify-components-passwordfield-button-hover-background-color: transparent;
  --amplify-components-passwordfield-button-hover-color: var(
    --amplify-components-button-color
  );
  [data-amplify-router] {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.progress-container {
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.auth-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;

  .sides {
    display: flex;
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 0;

    @media screen and (max-width: $view-md) {
      flex-basis: auto;
    }
  }
}

.user-role {
  position: relative;
  width: 100%;

  .user-role-wrapper {
    position: relative;
    display: flex;
    font-family: 'Sofia W05 Regular', sans-serif;
    flex: 1;
    width: 100%;

    button:first-child {
      margin-right: 20px;
    }

    button {
      flex: 1;
    }
  }

  .radio-group {
    position: absolute;
  }
}

.user-name {
  display: flex;

  .given_name {
    margin-right: 20px;
  }
}

[data-amplify-sign-up-errors='true'] {
  display: none;
}

.amplify-input--error {
  border-color: rgb(211, 47, 47);
}

.amplify-field__error-message,
.errorMessage {
  color: rgb(211, 47, 47);
  font-size: 12px;
}

.errorMessage {
  margin-top: 5px;
}

.back-wrapper {
  display: flex;

  a {
    color: #1976d2;
    font-family: Sofia W05 Light, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    cursor: pointer;
  }
}
