@import '../../../styles/globals.scss';

.admin-student-container {
  .student-title {
    font-family: 'Sofia W05 Semi Bold', sans-serif;
    color: #393a42;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    margin: 0;
  }

  .tabs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .MuiBox-root {
    padding: 0;
  }

  .student-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #393a42;
    margin-top: 12px;
  }

  .overview-info {
    margin-bottom: 16px;

    .title {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #393a42;
      opacity: 0.6;
    }

    .info {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #393a42;
      margin-top: 5.5px;
      min-height: 24px;

      &.isBlue {
        color: #1976d2;
      }
    }
  }

  .type-select {
    margin-bottom: 16px;
    #type-select-label {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #393a42;
      opacity: 0.6;
    }

    .MuiSelect-standard {
      font-family: 'Sofia W05 Regular', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #393a42;
    }
  }

  .admin-student-info-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-left: none;
    border-right: none;
    padding: 24px 0;

    .skill-interest-list {
      display: flex;
      gap: 4px;
      margin-bottom: 16px;
      margin-top: 16px;
      flex-wrap: wrap;
    }

    .student-container {
      .title {
        font-family: 'Sofia W05 Medium', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.001em;
        color: #393a42;
      }
    }

    .student-language-list {
      padding: 0;

      .language-list-item {
        padding: 0;
        margin: 12px 0;

        .language-item-avatar {
          width: 20px;
          min-width: 40px;
        }

        .student-language-item {
          white-space: nowrap;

          .language-label {
            font-family: 'Sofia W05 Regular', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #393a42;
          }

          .language-secondary-label {
            font-family: 'Sofia W05 Regular', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #393a42;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .toast-student {
    max-width: 520px;
    display: flex;
    align-items: center;
    margin: 16px 0 20px;

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #013654;
      width: 257px;
      font-family: 'Sofia W05 Regular', sans-serif;
    }

    .MuiAlert-message {
      display: flex;
      align-items: center;

      .MuiButtonBase-root {
        font-family: 'Sofia W05 Semi Bold', sans-serif;
        font-weight: 700;
        font-size: 13px;
        line-height: 23px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        color: #013654;
        margin-left: 33px;

        .MuiSvgIcon-root {
          margin-left: 10px;
          font-size: 16px;
          fill: #013654;
        }
      }
    }
  }

  #student-tab-0,
  #student-tab-1,
  #student-tab-2 {
    font-family: 'Sofia W05 Medium', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    font-variant: small-caps;
    color: #393a42;
    opacity: 0.5;

    &.Mui-selected {
      border-bottom-color: #deb900;
      color: #deb900;
    }
  }

  .rate-form-grid {
    padding-top: 24px;

    .form-input {
      width: 100%;
    }
  }

  .reset-button {
    margin-right: 8px;
  }

  .save-button-container {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    .save-button {
      width: auto;
      background: #deb900;
      border-radius: 4px;
      font-family: 'Sofia W05 Semi Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      color: #393a42;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  .company-info-container {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 24px;
    padding-top: 24px;

    .company-form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0;
      justify-content: flex-start;
      min-height: 0;

      .form-inputs {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .form-group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 16px;
      }
    }

    .kor-container {
      position: relative;
    }

    .kor-approved-at {
      margin: 12px 14px 0;
    }
  }

  .MuiTableCell-root {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }

  .highlight-student-wrapper {
    #highlight-student-button {
      font-weight: 700;
      font-size: 13px;
      line-height: 23px;
      color: #3bb142;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      font-family: 'Sofia W05 Semi Bold', sans-serif;

      &:disabled {
        color: #999999;
      }
    }
  }
}
